import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import styles from "./newWorkspace.module.css";
import Container from "../../components/container";
import { StatusContext } from "../../contexts/status";
import API from "../../api";
import { StateContext } from "../../contexts/state";

export default function NewWorkspace(props) {
  const { updateErrorMessage, updateSuccessMessage } = useContext(StatusContext);
  const { refreshWorkspaces } = useContext(StateContext);
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onCreateWorkspace = async () => {
    try {
      setIsLoading(true);
      await API.workspace().new(name);
      updateSuccessMessage("Workspace created, your workspace has been created");
      await refreshWorkspaces();
      setName("");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    onCreateWorkspace();
  };

  return (
    <Container {...props}>
      <h3 className={styles.title}>Create Workspace</h3>
      <form onSubmit={onSubmit}>
        <input
          type="text"
          placeholder="Name of the Workspace"
          className={styles.input}
          value={name}
          onChange={e => setName(e.target.value)}
          disabled={isLoading}
          required
        />
        <button type="submit" className={styles.button} disabled={isLoading}>
          Submit
        </button>
      </form>
    </Container>
  );
}

NewWorkspace.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired
  }),
  match: PropTypes.shape({
    params: PropTypes.object.isRequired
  })
};
