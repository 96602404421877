import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";
import API from "../../api";
import logo from "../../assets/images/logo.png";
import translate, { locale } from "../../translate";
import { StatusContext } from "../../contexts/status";
import { state } from "../../constants";
import routes from "../../routes";

const { TOKEN } = state;

function Invitation(props) {
  const {
    history,
    match: { params },
  } = props;
  const { updateErrorMessage, updateSuccessMessage } = useContext(StatusContext);
  const [channel, setChannel] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isRegistered, setIsRegistered] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const getData = async () => {
    try {
      setIsLoading(true);
      const { data: _channel } = await API.channel(params.id).get();
      setChannel(_channel.name);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      API.handleError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const validateEmail = async () => {
    try {
      setIsLoading(true);
      const {
        data: { isRegistered: _isRegistered },
      } = await API.user().validate(email.trim().toLowerCase());
      setIsRegistered(_isRegistered);
      setIsValidated(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const onSignIn = async () => {
    try {
      setIsLoading(true);
      const {
        data: { token },
      } = await API.user().signin(email, password, "web");
      localStorage.setItem(TOKEN, token);
      await API.channel(params.id).addReceiver(email, "#invitation");
      setIsLoading(true);
      window.location.href = "/";
    } catch (error) {
      setIsLoading(false);
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const onSignUp = async () => {
    try {
      setIsLoading(true);
      if (password !== confirmPassword) throw new Error("Passwords do not match");
      const {
        data: { token },
      } = await API.user().signup(firstName, lastName, email, password, locale);
      localStorage.setItem(TOKEN, token);
      await API.channel(params.id).addReceiver(email, "#invitation");
      updateSuccessMessage(
        "To finish your registration, please check your email in a few minutes." +
          " We sent you a verification email. Check the Spam folder too."
      );
      history.push(routes.signIn);
    } catch (error) {
      setIsLoading(false);
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img className={styles.logo} src={logo} alt="logo" />
        <h1 className={styles.title}>Tabular Plan</h1>
      </div>
      <h2>
        {translate.invitation.title} {channel}
      </h2>
      <br />
      <br />
      {isValidated ? (
        isRegistered ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSignIn();
            }}
          >
            <h4>{translate.invitation.email}</h4>
            <input
              className={styles.input}
              type="email"
              placeholder="john.doe@example.com"
              autoCapitalize="off"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={isLoading}
              required
            />
            <h4>{translate.signIn.password}</h4>
            <input
              className={styles.input}
              type="password"
              placeholder="********"
              autoCapitalize="off"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={isLoading}
              required
            />
            <button type="submit" className={styles.button} disabled={isLoading}>
              {translate.invitation.signIn}
            </button>
          </form>
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSignUp();
            }}
          >
            <h4>First Name</h4>
            <input
              className={styles.input}
              type="text"
              placeholder="John"
              autoCapitalize="on"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              disabled={isLoading}
              required
            />
            <h4>Last Name</h4>
            <input
              className={styles.input}
              type="text"
              placeholder="Doe"
              autoCapitalize="on"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              disabled={isLoading}
              required
            />
            <h4>Email</h4>
            <input
              className={styles.input}
              type="email"
              placeholder="john.doe@example.com"
              autoCapitalize="off"
              autoComplete="off"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={isLoading}
              required
            />
            <h4>Password</h4>
            <input
              className={styles.input}
              type="password"
              placeholder="********"
              autoCapitalize="off"
              autoComplete="off"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={isLoading}
              required
            />
            <h4>Confirm Password</h4>
            <input
              className={styles.input}
              type="password"
              placeholder="********"
              autoCapitalize="off"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              disabled={isLoading}
              required
            />
            <button type="submit" className={styles.button} disabled={isLoading}>
              Register
            </button>
          </form>
        )
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validateEmail();
          }}
        >
          <h4>{translate.invitation.email}</h4>
          <input
            className={styles.input}
            type="email"
            placeholder="john.doe@example.com"
            autoCapitalize="off"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={isLoading}
            required
          />
          <button type="submit" className={styles.button} disabled={isLoading}>
            {translate.invitation.submit}
          </button>
        </form>
      )}
    </div>
  );
}

Invitation.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }),
};

export default Invitation;
