const pt = {
  home: {
    newTableTitle: 'Você pode editar ou excluir esta tabela a qualquer momento, Vá para "Editar esta tabela" no menu lateral',
    ownTableDescription:
      "Configure cada coluna da tabela para que você ou sua equipe possam inserir facilmente as informações de cada linha a qualquer momento",
    preconfiguredDescription:
      "Escolha um quadro pronto para uso, como um que você pode usar como agenda, ou para gerenciar as tarefas da sua equipe e muito mais",
    title: "Digite Registro / Linha",
    sent: "Registro Criado",
    lastRecordEntered: "Último registro inserido"
  },
  newChannel: {
    title: "Defina as colunas/campos da nova tabela"
  },
  templates: {
    title:
      'Você pode editar ou excluir estas tabelas a qualquer momento em "Editar esta tabela" no menu lateral. Adicione ou remova colunas, altere tipos de variáveis ​​e muito mais.'
  },
  sidebar: {
    createTable: "Criar tabela",
    viewRows: "Ver linhas/registros inseridos",
    participants: "Participantes desta tabela",
    editTable: "Editar esta tabela",
    preconfiguredTables: "Tabelas préconfiguradas",
    sendMessages: "Enviar mensagens",
    myAccount: "Minha conta",
    contactUs: "Entre em contacto conosco/Envie feedback",
    help: "Perguntas frequentes"
  },
  members: {
    owner: "Dono",
    admins: "Admin",
    receivers: "Escritura e Leitura",
    senders: "Só Escritura",
    pendingInvitations: "Convites Pendentes"
  },
  summary: {
    title: "Ver Registros"
  }
};

export default pt;
