import React from "react";
import PropTypes from "prop-types";
import { FaCalendarDay, FaFileAlt, FaListUl, FaTasks } from "react-icons/fa";
import { MdTableChart } from "react-icons/md";
import classNames from "classnames";
import styles from "./styles.module.css";

function Icon(props) {
  const { name, ...rest } = props;

  if (name === "list-ul") {
    return <FaListUl {...rest} />;
  }
  if (name === "calendar-day") {
    return <FaCalendarDay {...rest} />;
  }
  if (name === "tasks") {
    return <FaTasks {...rest} />;
  }
  if (name === "file-alt") {
    return <FaFileAlt {...rest} />;
  }
  if (name === "table-edit") {
    return <MdTableChart {...rest} />;
  }
  return name;
}

function Template(props) {
  const { item, onSelect, selected } = props;

  return (
    <button
      className={classNames(styles.container, {
        [styles.containerSelected]: selected
      })}
      onClick={() => onSelect(item)}
      disabled={!item.active}
    >
      <div className={styles.iconContainer} style={{ backgroundColor: item.boxColor }}>
        <Icon name={item.icon} size={25} color={item.iconColor} />
      </div>
      <div className={styles.wrapper}>
        <b>{item.name}</b>
        <span>{item.description}</span>
      </div>
    </button>
  );
}

Icon.propTypes = {
  name: PropTypes.string.isRequired
};

Template.propTypes = {
  item: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  selected: PropTypes.bool
};

Template.defaultProps = {
  onSelect: () => {},
  selected: false
};

export default React.memo(Template);
