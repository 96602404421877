import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import shortid from "shortid";
import Container from "../../components/container";
import Template from "../../components/template";
import routes from "../../routes";
import API from "../../api";
import { StatusContext } from "../../contexts/status";
import { StateContext } from "../../contexts/state";
import translate from "../../translate";

function Templates(props) {
  const history = useHistory();
  const { updateErrorMessage } = useContext(StatusContext);
  const { workspace, updateChannel, user } = useContext(StateContext);

  const descriptionQuestion = {
    id: shortid.generate(),
    text: translate.templates.description,
    type: { text: "Text", value: "text" },
    isMandatory: true,
    dropdowns: [],
  };

  const dueDateQuestion = {
    id: shortid.generate(),
    text: translate.templates.dueDate,
    type: { text: "Date & Time", value: "datetime" },
    isMandatory: false,
    dropdowns: [],
  };

  const statusQuestion = {
    id: shortid.generate(),
    text: translate.templates.status,
    type: { text: "Dropdown", value: "dropdown" },
    isMandatory: true,
    dropdowns: [
      translate.templates.todo,
      translate.templates.inProgress,
      translate.templates.testing,
      translate.templates.resolved,
      translate.templates.notResolved,
    ],
  };

  const durationQuestion = {
    id: shortid.generate(),
    text: translate.templates.duration,
    type: { text: "Number", value: "number" },
    isMandatory: true,
    dropdowns: [],
  };

  const priorityQuestion = {
    id: shortid.generate(),
    text: translate.templates.priority,
    type: { text: "Dropdown", value: "dropdown" },
    isMandatory: false,
    dropdowns: [translate.templates.urgent, translate.templates.high, translate.templates.medium, translate.templates.low],
  };

  const typeQuestion = {
    id: shortid.generate(),
    text: translate.templates.type,
    type: { text: "Dropdown", value: "dropdown" },
    isMandatory: false,
    dropdowns: [translate.templates.family, translate.templates.work, translate.templates.friends],
  };

  const responsibleQuestion = {
    id: shortid.generate(),
    text: translate.templates.responsible,
    type: { text: "Dropdown", value: "dropdown" },
    isMandatory: true,
    dropdowns: [translate.templates.user1, translate.templates.user2, translate.templates.user3],
  };

  const listQuestions = [descriptionQuestion, dueDateQuestion, statusQuestion];

  const calendarQuestions = [descriptionQuestion, dueDateQuestion, durationQuestion, priorityQuestion, typeQuestion];

  const taskQuestions = [responsibleQuestion, descriptionQuestion, dueDateQuestion, statusQuestion, priorityQuestion];

  const organizeQuestions = [responsibleQuestion, descriptionQuestion, statusQuestion, typeQuestion];

  const onSelectTemplate = async (key) => {
    try {
      let name = "";
      let questions = [];
      if (key === "list") {
        name = translate.templates.listName;
        questions = listQuestions;
      } else if (key === "calendar") {
        name = translate.templates.calendarName;
        questions = calendarQuestions;
      } else if (key === "task") {
        name = translate.templates.taskName;
        questions = taskQuestions;
      } else if (key === "organize") {
        name = translate.templates.organizeName;
        questions = organizeQuestions;
      }
      questions = questions.map((question) => {
        const _question = { ...question };
        _question.type = question?.type?.value || undefined;
        return _question;
      });
      const channel = await API.channel().new(name, workspace._id, questions, null);
      // await Analytics.logEvent("new_channel", { name: `${user.firstName} ${user.lastName}` });
      updateChannel(channel.data);
      window.location.href = routes.tableView;
    } catch (error) {
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  return (
    <Container {...props}>
      <h4>{translate.templates.title}</h4>
      <br />
      <Template
        item={{
          key: "list",
          icon: "list-ul",
          iconColor: "#FFA710",
          boxColor: "rgba(188,138,1,0.15)",
          name: translate.templates.list.name,
          description: translate.templates.list.description,
          active: true,
        }}
        onSelect={({ key: _key }) => onSelectTemplate(_key)}
      />
      {user.isPaid && (
        <>
          <br />
          <Template
            item={{
              key: "calendar",
              icon: "calendar-day",
              iconColor: "#FF0000",
              boxColor: "rgba(188,1,14,0.15)",
              name: translate.templates.calendar.name,
              description: translate.templates.calendar.description,
              active: true,
            }}
            onSelect={({ key: _key }) => onSelectTemplate(_key)}
          />
        </>
      )}
      <br />
      <Template
        item={{
          key: "task",
          icon: "tasks",
          iconColor: "#00772C",
          boxColor: "rgba(1,188,70,0.15)",
          name: translate.templates.tasks.name,
          description: translate.templates.tasks.description,
          active: true,
        }}
        onSelect={({ key: _key }) => onSelectTemplate(_key)}
      />
      <br />
      <Template
        item={{
          key: "organize",
          icon: "file-alt",
          iconColor: "#0080FF",
          boxColor: "rgba(1,150,188,0.15)",
          name: translate.templates.organize.name,
          description: translate.templates.organize.description,
          active: true,
        }}
        onSelect={({ key: _key }) => onSelectTemplate(_key)}
      />
      <br />
      <Template
        item={{
          icon: "table-edit",
          iconColor: "#F9690E",
          boxColor: "rgba(249,105,14,0.15)",
          name: translate.templates.custom.name,
          description: translate.templates.custom.description,
          active: true,
        }}
        onSelect={() => history.push(routes.newChannel)}
        selected={false}
        materialCommunityIcons
      />
    </Container>
  );
}

Templates.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }),
};

export default Templates;
