import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Loader from "react-loader-spinner";
import { ImSortAmountAsc, ImSortAmountDesc } from "react-icons/im";
import { Link } from "react-router-dom";
import shortid from "shortid";
import styles from "./styles.module.css";
import Container from "../../components/container";
import { StateContext } from "../../contexts/state";
import API from "../../api";
import { StatusContext } from "../../contexts/status";
import { getColorFormat } from "../../utils";
import classNames from "classnames";
import routes from "../../routes";
import translate from "../../translate";

const formatAnswer = (answer) => {
  if (!answer) {
    return "";
  }
  if (answer.question.type === "datetime" && answer.answer) {
    return moment(answer.answer).format("YYYY-MM-DD hh:mm:ss A");
  }
  if (answer.question.type === "time" && answer.answer) {
    return moment(answer.answer).format("hh:mm A");
  }
  return answer.answer || "";
};

function TableView(props) {
  const { channel } = useContext(StateContext);
  const { updateErrorMessage, updateSuccessMessage } = useContext(StatusContext);
  const [informations, setInformations] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [query, setQuery] = useState("");
  const [order, setOrder] = useState(localStorage.getItem("order") || "creation");
  const [orderDir, setOrderDir] = useState(localStorage.getItem("orderdir") || "-1");
  const [isExporting, setIsExporting] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [hasComments, setHasComments] = useState(false);

  const fetchData = async () => {
    try {
      setRefreshing(true);
      const { data: _questions } = await API.channel(channel._id).table();
      setQuestions(_questions);
      const params = API.getParams({
        channel: channel._id,
        isArchived: false,
        order,
        orderDir,
        filter: "All",
        limit: 0,
      }).replace("#", "%23");
      const response = await API.information().list(params);
      const _informations = response.data;
      setInformations(_informations);
      setHasComments(_informations.some((_data) => _data.comments.length > 0));
      setRefreshing(false);
    } catch (error) {
      setRefreshing(false);
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  useEffect(() => {
    fetchData();
  }, [channel, order, orderDir]);

  useEffect(() => {
    if (order !== "creation" && orderDir) {
      localStorage.setItem("order", order);
      localStorage.setItem("orderdir", orderDir);
    } else {
      localStorage.removeItem("order");
      localStorage.removeItem("orderdir");
    }
  }, [order, orderDir]);

  const onExport = async () => {
    try {
      setIsExporting(true);
      await API.file().reportChannel(channel._id);
      updateSuccessMessage("Email sent");
      setIsExporting(false);
    } catch (error) {
      setIsExporting(false);
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  return (
    <Container {...props}>
      {refreshing ? (
        <div className={styles.loaderContainer}>
          <Loader type="TailSpin" color="#012172" height={50} width={50} />
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.inputContainer}>
            <input
              type="search"
              placeholder={translate.table.search}
              className={styles.input}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            <button className={styles.exportButton} onClick={onExport} disabled={isExporting}>
              {translate.table.export}
            </button>
          </div>
          <div className={styles.wrapper}>
            <table className={styles.table}>
              <thead>
                <tr>
                  {questions.map((question) => (
                    <th
                      key={question._id}
                      className={styles.cell}
                      onClick={() => {
                        const _order = `question#${question._id}`;
                        if (order === _order) {
                          setOrderDir(orderDir === "1" ? "-1" : "1");
                        } else {
                          setOrder(`question#${question._id}`);
                          setOrderDir("-1");
                        }
                      }}
                    >
                      {question.text}
                      {order === `question#${question._id}` && (orderDir === "1" ? <ImSortAmountDesc /> : <ImSortAmountAsc />)}
                    </th>
                  ))}
                  {hasComments && <th>{translate.table.comments}</th>}
                </tr>
              </thead>
              <tbody>
                {informations.map((information) => (
                  <Link
                    key={information._id}
                    className={styles.row}
                    style={{ backgroundColor: getColorFormat(information.color, 0.1) }}
                    to={routes.summary.concat(`?id=${information._id}`)}
                  >
                    {questions.map((question) => (
                      <td
                        key={question._id}
                        className={classNames({
                          [styles.cellActive]:
                            query &&
                            formatAnswer(information.answers.find((answer) => answer.question._id === question._id))
                              .toLowerCase()
                              .includes(query.toLowerCase()),
                        })}
                      >
                        {formatAnswer(information.answers.find((answer) => answer.question._id === question._id))}
                      </td>
                    ))}
                    {hasComments && <td>{information.comments.map((c) => c.text).join(". ")}</td>}
                  </Link>
                ))}
                {informations.length < 5 &&
                  new Array(5 - informations.length).fill(0).map(() => (
                    <div key={shortid.generate()} className={styles.row}>
                      {questions.map((question) => (
                        <td key={question._id}>
                          <span style={{ color: "transparent" }}>-</span>
                        </td>
                      ))}
                    </div>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <Link className={styles.enterInformationButton} to={routes.home}>
        {translate.table.add}
      </Link>
    </Container>
  );
}

TableView.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }),
};

export default TableView;
