import React, { useContext } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";
import { IoGlobeOutline } from "react-icons/io5";
import isUrl from "is-url";
import styles from "./answer.module.css";
import { StateContext } from "../../contexts/state";
import { StatusContext } from "../../contexts/status";

function getRule(user, rules) {
  if (user.isPaid) {
    return rules.gold;
  }
  if (user.isPremium) {
    return rules.premium;
  }
  return rules.free;
}

function CustomInput(props) {
  const { _id, type, answer, updateAnswer, isMandatory, dropdowns, disabled } = props;
  const { user, rules } = useContext(StateContext);
  const { updateWarningMessage } = useContext(StatusContext);

  const rule = getRule(user, rules);

  const onPictureAdd = async (e) => {
    if (Array.isArray(answer) && answer.length >= rule.max_photos) {
      e.target.value = "";
      if (user.isPaid) {
        updateWarningMessage("Maximum number of pictures. You have reached the maximum amount of pictures for this post");
      } else {
        updateWarningMessage("Upgrade your account. You have reached the maximum amount of pictures for this post");
      }
      return;
    }
    if (e.target?.files?.length > 0) {
      if (Array.isArray(answer)) {
        const _answer = [...answer];
        _answer.push(e.target.files[0]);
        updateAnswer(_id, _answer);
      } else {
        const _answer = [e.target.files[0]];
        updateAnswer(_id, _answer);
      }
      e.target.value = "";
    }
  };

  switch (type) {
    case "number":
      return (
        <input
          type="number"
          placeholder="Enter data here"
          className={styles.input}
          value={answer}
          onChange={(e) => updateAnswer(_id, e.target.value)}
          required={isMandatory}
          disabled={disabled}
        />
      );
    case "datetime":
      return (
        <input
          type="datetime-local"
          placeholder="Enter data here"
          className={styles.input}
          value={answer}
          onChange={(e) => updateAnswer(_id, e.target.value)}
          required={isMandatory}
          disabled={disabled}
        />
      );
    case "time":
      return (
        <input
          type="time"
          placeholder="Enter data here"
          className={styles.input}
          value={moment(answer).format("HH:mm")}
          onChange={(e) => updateAnswer(_id, moment(e.target.value, "HH:mm").toISOString())}
          required={isMandatory}
          disabled={disabled}
        />
      );
    case "date":
      return (
        <input
          type="date"
          placeholder="Enter data here"
          className={styles.input}
          value={answer}
          onChange={(e) => updateAnswer(_id, e.target.value)}
          required={isMandatory}
          disabled={disabled}
        />
      );
    case "dropdown":
      return (
        <select
          className={styles.select}
          value={answer}
          onChange={(e) => updateAnswer(_id, e.target.value)}
          required={isMandatory}
          disabled={disabled}
        >
          <option value="" disabled>
            Select option
          </option>
          {dropdowns.map((dropdown, index) => (
            <option key={String(index)} value={dropdown}>
              {dropdown}
            </option>
          ))}
        </select>
      );
    case "picture":
      return (
        <div>
          {answer && Array.isArray(answer) && (
            <div className={styles.imagesContainer}>
              {answer.map((file, index) => (
                <img
                  key={String(index)}
                  className={styles.image}
                  src={typeof file === "string" ? file : URL.createObjectURL(file)}
                  alt={`file #${index}`}
                />
              ))}
            </div>
          )}
          <input
            type="file"
            accept="image/*"
            placeholder="Enter data here"
            className={styles.input}
            onChange={onPictureAdd}
            required={isMandatory ? !answer || answer.length < 1 : false}
            disabled={disabled}
          />
        </div>
      );
    case "url":
      return (
        <div className={styles.url}>
          <input
            type="url"
            placeholder="Enter data here"
            className={classNames(styles.input, styles.textarea)}
            value={answer}
            onChange={(e) => updateAnswer(_id, e.target.value)}
            required={isMandatory}
            disabled={disabled}
          />
          {isUrl(answer) && (
            <a className={styles.urlButton} href={answer} target="_blank" rel="noopener noreferrer">
              <IoGlobeOutline />
            </a>
          )}
        </div>
      );
    case "video":
      return (
        <div>
          {answer && Array.isArray(answer) && (
            <div className={styles.imagesContainer}>
              {answer.map((file, index) => (
                <video
                  key={String(index)}
                  controls
                  className={styles.video}
                  src={typeof file === "string" ? file : URL.createObjectURL(file)}
                />
              ))}
            </div>
          )}
          <input
            type="file"
            accept="video/mp4,video/x-m4v,video/*"
            placeholder="Enter data here"
            className={styles.input}
            onChange={onPictureAdd}
            required={isMandatory ? !answer || answer.length < 1 : false}
            disabled={disabled}
          />
        </div>
      );
    case "checkmark":
      return (
        <input
          type="checkbox"
          placeholder="Enter data here"
          className={styles.input}
          checked={Boolean(answer)}
          onChange={(e) => updateAnswer(_id, e.target.checked ? "true" : "")}
          required={isMandatory}
          disabled={disabled}
        />
      );
    default:
      return (
        <textarea
          placeholder="Enter data here"
          rows={2}
          className={classNames(styles.input, styles.textarea)}
          value={answer}
          onChange={(e) => updateAnswer(_id, e.target.value)}
          required={isMandatory}
          disabled={disabled}
        />
      );
  }
}

export default function Answer(props) {
  const { text, isMandatory } = props;

  return (
    <div>
      <label className={styles.label}>
        {text} {isMandatory && <span className={styles.mandatory}>*</span>}
      </label>
      <CustomInput {...props} />
    </div>
  );
}

CustomInput.propTypes = {
  _id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isMandatory: PropTypes.bool,
  answer: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  updateAnswer: PropTypes.func.isRequired,
  dropdowns: PropTypes.array,
  disabled: PropTypes.bool.isRequired,
};

Answer.propTypes = {
  _id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isMandatory: PropTypes.bool,
  answer: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  updateAnswer: PropTypes.func.isRequired,
  dropdowns: PropTypes.array,
  disabled: PropTypes.bool.isRequired,
};
