import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styles from "./forgotPassword.module.css";
import { StatusContext } from "../../contexts/status";
import API from "../../api";
import routes from "../../routes";

export default function ForgotPassword(props) {
  const { history } = props;
  const { updateErrorMessage, updateSuccessMessage } = useContext(StatusContext);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onResetPassword = async () => {
    try {
      setIsLoading(true);
      const params = API.getParams({ email });
      await API.user().password().reset(params);
      updateSuccessMessage("Please check your inbox in a few minutes and click the link provided to reset the password.");
      history.push(routes.signIn);
    } catch (error) {
      setIsLoading(false);
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    onResetPassword();
  };

  return (
    <div className={styles.container}>
      <h2>Reset password</h2>
      <br />
      <br />
      <p className={styles.message}>
        Enter the email associated with you account and we&apos;ll send an email with instrucctions to reset your password
      </p>
      <br />
      <br />
      <form onSubmit={onSubmit}>
        <h4>Email</h4>
        <input
          className={styles.input}
          type="email"
          placeholder="john.doe@example.com"
          autoCapitalize="off"
          value={email}
          onChange={e => setEmail(e.target.value)}
          disabled={isLoading}
          required
        />
        <button type="submit" className={styles.button} disabled={isLoading}>
          Reset password
        </button>
      </form>
      <br />
      <div className={styles.actions}>
        <span>Remember password?</span>
        <Link to={routes.signIn}>
          <button className={styles.buttonLink} disabled={isLoading}>
            Sign in
          </button>
        </Link>
      </div>
    </div>
  );
}

ForgotPassword.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired
  }),
  match: PropTypes.shape({
    params: PropTypes.object.isRequired
  })
};
