import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import firebase from "firebase/app";
import { Archive, DeleteForever, Unarchive } from "@material-ui/icons";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { capitalize } from "@material-ui/core";
import styles from "./styles.module.css";
import Answer from "../answer";
import { StateContext } from "../../contexts/state";
import { roles } from "../../utils";
import { postColors } from "../../constants";
import API from "../../api";
import { StatusContext } from "../../contexts/status";
import translate from "../../translate";

function Details(props) {
  const { onDeleteConfirm } = props;
  const { user, role } = useContext(StateContext);
  const { updateErrorMessage, updateSuccessMessage, updateWarningMessage } = useContext(StatusContext);
  const [information, setInformation] = useState(props.information);
  const [postColor, setPostColor] = useState(postColors[0].value);
  const [colorChanged, setColorChanged] = useState(false);
  const [comment, setComment] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [personalAlert, setPersonalAlert] = useState({});
  const [teamAlert, setTeamAlert] = useState({});
  const [periodAlert, setPeriodAlert] = useState({});
  const [responsibleAlert, setResponsibleAlert] = useState({});
  const [reminderDate, setReminderDate] = useState(null);
  const [reminderTeamDate, setReminderTeamDate] = useState(null);
  const [reminderPeriod, setReminderPeriod] = useState("");
  const [reminderPeriodDate, setReminderPeriodDate] = useState(null);
  const [reminderResponsibleDate, setReminderResponsibleDate] = useState(null);

  useEffect(() => {
    if (!user.hasTokens && (reminderDate || reminderTeamDate || reminderPeriodDate || reminderResponsibleDate)) {
      setReminderDate(null);
      setReminderTeamDate(null);
      setReminderPeriodDate(null);
      setReminderResponsibleDate(null);
      updateWarningMessage("You must download the app");
    }
  }, [user.hasTokens, reminderDate, reminderTeamDate, reminderPeriodDate, reminderResponsibleDate]);

  useEffect(() => {
    console.log(props.information?.alerts);
    setInformation(props.information);
    setPostColor(props.information.color);
    const _personalAlert = props.information?.alerts?.find((alert) => alert.type === "personal");
    const _teamAlert = props.information?.alerts?.find((alert) => alert.type === "team");
    const _periodAlert = props.information?.alerts?.find((alert) => alert.type === "period");
    const _responsibleAlert = props.information?.alerts?.find((alert) => alert.type === "responsible");
    setPersonalAlert(_personalAlert);
    setTeamAlert(_teamAlert);
    setPeriodAlert(_periodAlert);
    setResponsibleAlert(_responsibleAlert);
    setReminderDate(_personalAlert?.date ? moment(_personalAlert?.date).toDate() : null);
    setReminderTeamDate(_teamAlert?.date ? moment(_teamAlert?.date).toDate() : null);
    setReminderPeriod(_periodAlert?.period);
    setReminderPeriodDate(_periodAlert?.date ? moment(_periodAlert?.date).toDate() : null);
    setReminderResponsibleDate(_responsibleAlert?.date ? moment(_responsibleAlert?.date).toDate() : null);
    setColorChanged(false);
    firebase.database().ref("events").child("informations").child(props.information._id).child(user._id).set(true);
    firebase.database().ref("events").child("comments").child(props.information._id).child(user._id).set(true);
  }, [props.information]);

  const onUpdateInformation = async (update = true) => {
    try {
      setIsSaving(true);
      window.onbeforeunload = function () {
        return "Post is being saved";
      };
      if (personalAlert) {
        if (reminderDate) {
          await API.alert(personalAlert._id).update(reminderDate, personalAlert.period);
        } else {
          await API.alert(personalAlert._id).delete();
        }
      } else if (reminderDate) {
        await API.alert().new({
          information: information._id,
          date: reminderDate,
          receiver: user._id,
          type: "personal",
        });
      }
      if (teamAlert) {
        if (reminderTeamDate) {
          await API.alert(teamAlert._id).update(reminderTeamDate, teamAlert.period);
        } else {
          await API.alert(teamAlert._id).delete();
        }
      } else if (reminderTeamDate) {
        await API.alert().new({ information: information._id, date: reminderTeamDate, receiver: user._id, type: "team" });
      }
      if (periodAlert) {
        if (reminderPeriod) {
          await API.alert(periodAlert._id).update(reminderPeriodDate, reminderPeriod);
        } else {
          await API.alert(periodAlert._id).delete();
        }
      } else if (reminderPeriod) {
        await API.alert().new({
          information: information._id,
          date: moment(reminderPeriodDate).toISOString(),
          receiver: user._id,
          type: "period",
          period: reminderPeriod,
        });
      }
      if (responsibleAlert) {
        if (reminderResponsibleDate) {
          await API.alert(responsibleAlert._id).update(reminderResponsibleDate, responsibleAlert.period);
        } else {
          await API.alert(responsibleAlert._id).delete();
        }
      } else if (reminderResponsibleDate) {
        await API.alert().new({
          information: information._id,
          date: reminderResponsibleDate,
          receiver: user._id,
          type: "responsible",
        });
      }
      let _answers = information.answers.map((answer) => ({ ...answer, question: answer.question._id }));
      const answers = await Promise.all(
        _answers.map(async (answer) => {
          if (!Array.isArray(answer.answer)) {
            return answer;
          }
          const _answer = await Promise.all(
            answer.answer.map(async (file) => {
              if (typeof file === "string") {
                return file;
              }
              const response = await API.file().upload(file);
              return response.data.url;
            })
          );
          return { ...answer, answer: _answer.join(";") };
        })
      );
      const _comments = [...information.comments];
      if (comment.trim()) {
        _comments.push({
          text: comment,
          sender: user._id,
        });
      }
      const data = {
        answers,
        comments: _comments,
        color: postColor,
      };
      const response = await API.information(information._id).update(data);
      if (update) {
        setInformation(response.data);
        setColorChanged(false);
      }
      updateSuccessMessage("Saved");
      if (comment.trim()) {
        await firebase.database().ref("events").child("comments").child(information._id).set(null);
        await firebase.database().ref("events").child("comments").child(information._id).child(user._id).set(true);
      }
      setComment("");
      await firebase.database().ref("events").child("informations").child(information._id).set(null);
      await firebase.database().ref("events").child("informations").child(information._id).child(user._id).set(true);
      setIsSaving(false);
      window.onbeforeunload = function () {
        return null;
      };
    } catch (error) {
      setIsSaving(false);
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    onUpdateInformation();
  };

  const getAnswer = (answer) => {
    if (answer.question.type === "picture" && answer.answer && typeof answer.answer === "string") {
      return answer.answer.split(";");
    }
    return answer.answer || "";
  };

  const updateAnswer = (answer, value) => {
    const _information = { ...information };
    _information.answers = _information.answers.map((_answer) => {
      if (_answer._id === answer) {
        return { ..._answer, answer: value };
      }
      return _answer;
    });
    setInformation(_information);
  };

  const onArchive = async () => {
    try {
      await API.information(information._id).update({
        deletedBy: user._id,
        deletedAt: moment().toISOString(),
      });
      onDeleteConfirm();
      updateSuccessMessage("Archived");
    } catch (error) {
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const onUnarchive = async () => {
    try {
      await API.information(information._id).update({
        deletedBy: null,
        deletedAt: null,
      });
      onDeleteConfirm();
      updateSuccessMessage("Unarchived");
    } catch (error) {
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const onDelete = async () => {
    try {
      const isConfirm = window.confirm("Are you sure? This action can not be reversed");
      if (isConfirm) {
        await API.information(information._id).delete();
        onDeleteConfirm();
      }
    } catch (error) {
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const periods = [
    {
      label: translate.reminders.daily,
      value: "daily",
    },
    {
      label: translate.reminders.everyotherday,
      value: "everyotherday",
    },
    {
      label: translate.reminders.weekly,
      value: "weekly",
    },
    {
      label: translate.reminders.monthly,
      value: "monthly",
    },
    {
      label: translate.reminders.onceayear,
      value: "onceayear",
    },
  ];

  const isEdited =
    JSON.stringify(information) === JSON.stringify(props.information) &&
    !comment.trim() &&
    !colorChanged &&
    personalAlert?.date === reminderDate &&
    teamAlert?.date === reminderTeamDate &&
    periodAlert?.period === reminderPeriod &&
    periodAlert?.date === reminderPeriodDate &&
    responsibleAlert?.date === reminderResponsibleDate;

  return (
    <div className={styles.container}>
      <div className={styles.containerWrapper}>
        <div className={styles.actionsContainer}>
          {information?.haveSubtasks && <Link>See subtasks</Link>}
          {role === roles.ADMIN &&
            user.isPaid &&
            (information.deletedAt && information.deletedBy ? (
              <button onClick={onUnarchive} title={translate.details.unarchive}>
                <Unarchive />
              </button>
            ) : (
              <button onClick={onArchive} title={translate.details.archive}>
                <Archive />
              </button>
            ))}

          {role === roles.ADMIN && (
            <button onClick={onDelete} title={translate.details.delete}>
              <DeleteForever />
            </button>
          )}
        </div>
      </div>
      <form className={styles.wrapper} onSubmit={onSubmit}>
        <h4 className={styles.identifier}>ID: #{information.identifier}</h4>
        {information?.answers?.map((answer) => (
          <Answer
            {...answer.question}
            _id={answer._id}
            key={answer._id}
            updateAnswer={updateAnswer}
            answer={getAnswer(answer)}
            disabled={role !== roles.ADMIN || information?.sender?._id !== user._id}
          />
        ))}
        <br />
        <DatePicker
          minDate={new Date()}
          selected={reminderDate}
          showTimeSelect
          customInput={
            <div className={styles.checkbox}>
              <input
                type="checkbox"
                checked={Boolean(reminderDate)}
                onChange={(e) => {
                  if (!e.target.checked) {
                    setReminderDate(null);
                  }
                }}
              />
              <span>
                {translate.home.reminderAt}: {reminderDate ? moment(reminderDate).format("Y-MM-DD h:mm A") : translate.home.none}
              </span>
            </div>
          }
          onChange={setReminderDate}
          disabled={isSaving}
        />
        {user.isPaid && (
          <DatePicker
            minDate={new Date()}
            selected={reminderTeamDate}
            showTimeSelect
            customInput={
              <div className={styles.checkbox}>
                <input
                  type="checkbox"
                  checked={Boolean(reminderTeamDate)}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      setReminderTeamDate(null);
                    }
                  }}
                />
                <span>
                  {translate.home.reminderTeam}:{" "}
                  {reminderTeamDate ? moment(reminderTeamDate).format("Y-MM-DD h:mm A") : translate.home.none}
                </span>
              </div>
            }
            onChange={setReminderTeamDate}
            disabled={isSaving}
          />
        )}
        <div className={styles.checkbox}>
          <input
            type="checkbox"
            checked={Boolean(reminderPeriod)}
            onChange={(e) => {
              if (!e.target.checked) {
                setReminderPeriod("");
              }
            }}
          />
          <span>{translate.home.reminderToMe}: </span>
          <select className={styles.selectPeriod} value={reminderPeriod} onChange={(e) => setReminderPeriod(e.target.value)}>
            <option value="">{capitalize(translate.home.none)}</option>
            {periods.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {Boolean(reminderPeriod) && (
            <DatePicker
              minDate={new Date()}
              selected={reminderPeriodDate}
              showTimeSelect
              customInput={
                <span>
                  {reminderPeriodDate ? (
                    moment(reminderPeriodDate).format("Y-MM-DD h:mm A")
                  ) : (
                    <b>
                      <u>{translate.home.none}</u>
                    </b>
                  )}
                </span>
              }
              onChange={setReminderPeriodDate}
              disabled={isSaving}
              wrapperClassName={styles.datePicker}
            />
          )}
        </div>
        {user.isPaid && (
          <DatePicker
            minDate={new Date()}
            selected={reminderResponsibleDate}
            showTimeSelect
            customInput={
              <div className={styles.checkbox}>
                <input
                  type="checkbox"
                  checked={Boolean(reminderResponsibleDate)}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      setReminderResponsibleDate(null);
                    }
                  }}
                />
                <span>
                  {translate.home.reminderTeam}:{" "}
                  {reminderResponsibleDate ? moment(reminderResponsibleDate).format("Y-MM-DD h:mm A") : translate.home.none}
                </span>
              </div>
            }
            onChange={setReminderResponsibleDate}
            disabled={isSaving}
          />
        )}
        <br />
        <div>
          <span className={styles.label}>Color</span>
          <div className={styles.colorsContainer}>
            {postColors.map((_postColor) => (
              <button
                type="button"
                key={_postColor.value}
                className={styles.colorButton}
                style={{
                  backgroundColor: _postColor.value,
                  borderColor: _postColor.value === postColor ? "black" : "white",
                }}
                onClick={() => {
                  setPostColor(_postColor.value);
                  setColorChanged(_postColor.value !== information.color);
                }}
              />
            ))}
          </div>
        </div>
        <br />
        <div>
          <span className={styles.label}>Comments</span>
          <div>
            {information?.comments?.map((_comment) => (
              <div key={_comment._id} className={styles.input} style={{ color: `#${_comment.sender._id.slice(-6)}` }}>
                <b>{_comment.text}</b>
                <p>
                  {_comment.sender.firstName} {_comment.sender.lastName}
                </p>
                <p>{moment(_comment.createdAt).format("YYYY-MM-DD hh:mm:ss A")}</p>
              </div>
            ))}
          </div>
          <input
            type="text"
            placeholder="Write your comment here"
            className={styles.input}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
        <br />
        <div>
          <span className={styles.label}>Post sent by</span>
          <div className={styles.input}>
            <b>{`${information?.sender?.firstName || ""} ${information?.sender?.lastName || ""}`}</b>
            <p>{moment(information.createdAt).format("YYYY-MM-DD hh:mm:ss A")}</p>
          </div>
        </div>
        <button type="submit" className={styles.submitButton} disabled={isEdited || isSaving}>
          Submit
        </button>
      </form>
    </div>
  );
}

export default React.memo(Details);
