import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styles from "./addMember.module.css";
import Container from "../../components/container";
import { StateContext } from "../../contexts/state";
import { StatusContext } from "../../contexts/status";
import { roles } from "../../utils";
import API from "../../api";
import routes from "../../routes";
import translate from "../../translate";

export default function AddMember(props) {
  const { history } = props;
  const { channel, role, refreshChannel } = useContext(StateContext);
  const { updateErrorMessage, updateSuccessMessage } = useContext(StatusContext);
  const [email, setEmail] = useState("");
  const [option, setOption] = useState("sender");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setOption("sender");
  }, [role]);

  const onAddParticipant = async () => {
    try {
      setIsLoading(true);
      if (option === "admin") {
        await API.channel(channel._id).addAdmin(email, message);
      } else if (option === "receiver") {
        await API.channel(channel._id).addReceiver(email, message);
      } else {
        await API.channel(channel._id).addSender(email, message);
      }
      await refreshChannel();
      setEmail("");
      setOption("sender");
      setMessage("");
      updateSuccessMessage(`${translate.addNewParticipant.success} ${translate.addNewParticipant[option]}`);
      history.push(routes.tableView);
    } catch (error) {
      setIsLoading(false);
      if (error.isAxiosError && error.response.status === 428) {
        setEmail("");
        setOption("sender");
        setMessage("");
        updateSuccessMessage(
          `${translate.addNewParticipant.invitation.body1} ${email} ${translate.addNewParticipant.invitation.body2}`
        );
        history.push(routes.tableView);
      } else {
        updateErrorMessage(API.getErrorMessage(error));
      }
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    onAddParticipant();
  };

  return (
    <Container {...props}>
      <h3 className={styles.title}>
        {translate.addNewParticipant.title} {channel.name}
      </h3>
      <br />
      <Link to={routes.tableView} className={styles.skip}>
        {translate.addMember.skip}
      </Link>
      <form onSubmit={onSubmit}>
        <input
          type="email"
          className={styles.input}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="john.doe@example.com"
          disabled={isLoading}
          required
        />
        {role === roles.ADMIN && (
          <select
            className={styles.select}
            value={option}
            onChange={(e) => setOption(e.target.value)}
            disabled={isLoading}
            required
          >
            <option value="sender">{translate.addNewParticipant.sender}</option>
            <option value="receiver">{translate.addNewParticipant.receiver}</option>
            <option value="admin">{translate.addNewParticipant.admin}</option>
          </select>
        )}
        <textarea
          className={styles.input}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder={translate.addNewParticipant.privateMessage}
          disabled={isLoading}
        />
        <button type="submit" className={styles.button} disabled={isLoading}>
          Add
        </button>
      </form>
    </Container>
  );
}

AddMember.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }),
};
