import React, { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { NotificationsNone } from "@material-ui/icons";
import classNames from "classnames";
import firebase from "firebase/app";
import moment from "moment";
import styles from "./information.module.css";
import { formatAnswer, getColorFormat, getMonth } from "../../utils";
import routes from "../../routes";
import { StateContext } from "../../contexts/state";
import API from "../../api";
import { StatusContext } from "../../contexts/status";
import StatusPicker from "../statusPicker";

export default function Information(props) {
  const {
    information: item,
    showAllInfo,
    filterIndex,
    selectedInformation,
    onSelect,
    isSelect,
    toggleSelectedInformation,
  } = props;
  const { user } = useContext(StateContext);
  const { updateErrorMessage } = useContext(StatusContext);
  const [information, setInformation] = useState(item);
  const [isNewData, setIsNewData] = useState(false);
  const [isNewComment, setIsNewComment] = useState(false);

  const { color } = information;

  const fetchData = useCallback(
    async (id = "") => {
      try {
        const _information = await API.information(id).get();
        setInformation(_information.data);
      } catch (error) {
        updateErrorMessage(API.getErrorMessage(error));
      }
    },
    [updateErrorMessage]
  );

  useEffect(() => {
    firebase
      .database()
      .ref("events")
      .child("informations")
      .child(information._id)
      .child(user._id)
      .on("value", (data) => {
        const value = data.val();
        if (value) {
          setIsNewData(false);
        } else {
          setIsNewData(true);
          fetchData(information._id);
        }
      });
    firebase
      .database()
      .ref("events")
      .child("comments")
      .child(information._id)
      .child(user._id)
      .on("value", (data) => {
        const value = data.val();
        if (value) {
          setIsNewComment(false);
        } else {
          setIsNewComment(true);
        }
      });
    return () => {
      firebase.database().ref("events").child("informations").child(information._id).child(user._id).off("value");
      firebase.database().ref("events").child("comments").child(information._id).child(user._id).off("value");
    };
  }, [fetchData, information._id, user._id]);

  return (
    <div
      className={classNames(styles.informationContainer, {
        [styles.informationContainerActive]: selectedInformation.includes(information._id),
      })}
      style={{
        backgroundColor: !selectedInformation.includes(information._id) && getColorFormat(color, 0.1),
      }}
    >
      {isSelect && (
        <input
          type="checkbox"
          checked={selectedInformation.includes(information._id)}
          onChange={() => toggleSelectedInformation(information._id)}
        />
      )}
      <button onClick={() => onSelect(information)} className={styles.informationLink}>
        {showAllInfo && (
          <div className={styles.senderNameWrapper} style={{ fontWeight: isNewData ? "bold" : "normal" }}>
            <span>
              {information.sender.firstName} {information.sender.lastName}
              {information.comments.length > 0 && (
                <span className={styles.asterisk} style={{ color: isNewComment ? "red" : "grey" }}>
                  *
                </span>
              )}
            </span>
          </div>
        )}
        {filterIndex !== null && <span>{formatAnswer(information.answers.find((a) => a.question._id === filterIndex))}</span>}
        {information.answers
          .filter((answer) => answer.answer)
          .map((answer, index) => {
            if (!showAllInfo && index >= 3) return null;
            if (filterIndex && answer.question._id === filterIndex) return null;
            return (
              <div key={answer._id} className={styles.informationWrapper}>
                <div className={styles.informationAnswer} style={{ fontWeight: isNewData ? "bold" : "normal" }}>
                  {answer.question.type === "picture" ? (
                    answer.answer
                      .split(";")
                      .map((uri) => <img key={uri} src={uri} className={styles.informationImage} alt={uri} />)
                  ) : (
                    <span>{formatAnswer(answer)}</span>
                  )}
                  {Boolean(information.comments.length) && !showAllInfo && index === 0 && (
                    <span className={styles.asterisk} style={{ color: isNewComment ? "red" : "grey" }}>
                      *
                    </span>
                  )}
                </div>
              </div>
            );
          })}
      </button>
      <div className={styles.extra}>
        {user.isPaid && <StatusPicker status={information.resolvedStatus} updateStatus={() => {}} />}
        {item.identifier && user.isPaid && (
          <small>
            {getMonth(moment(information.createdAt).month())}
            {information.identifier}
          </small>
        )}
        {information.haveAlert && (
          <Link to={routes.alert.replace(":id", information._id)} className={styles.informationAlert}>
            <NotificationsNone fontSize="small" />
          </Link>
        )}
      </div>
    </div>
  );
}

Information.propTypes = {
  information: PropTypes.object.isRequired,
  showAllInfo: PropTypes.bool,
  filterIndex: PropTypes.any,
  selectedInformation: PropTypes.array,
  toggleSelectedInformation: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  isSelect: PropTypes.bool.isRequired,
};

Information.defaultProps = {
  showAllInfo: false,
  filterIndex: null,
  selectedInformation: [],
  toggleSelectedInformation: () => {},
  informations: [],
  isSelect: false,
};
