import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import styles from "./feedback.module.css";
import Container from "../../components/container";
import API from "../../api";
import { StatusContext } from "../../contexts/status";
import translate from "../../translate";

export default function Feedback(props) {
  const { updateErrorMessage, updateSuccessMessage } = useContext(StatusContext);
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [priority, setPriority] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onSendFeedback = async () => {
    try {
      setIsLoading(true);
      await API.feedback().new(type, message, priority);
      setType("");
      setMessage("");
      setPriority("");
      updateSuccessMessage("Your feedback was sent. Thank you");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    onSendFeedback();
  };

  return (
    <Container {...props}>
      <h3 className={styles.title}>{translate.feedback.title}</h3>
      <br />
      <form className={styles.form} onSubmit={onSubmit}>
        <label className={styles.label}>{translate.feedback.typeOfFeedback}</label>
        <select className={styles.select} value={type} onChange={e => setType(e.target.value)} required disabled={isLoading}>
          <option value="" disabled>
            {translate.feedback.selectType}
          </option>
          <option value="problem/bug">{translate.feedback.problem}</option>
          <option value="add new feature">{translate.feedback.feature}</option>
          <option value="improvement">{translate.feedback.improvement}</option>
          <option value="comment">{translate.feedback.comment}</option>
        </select>
        <br />
        <br />
        <label className={styles.label}>{translate.feedback.howImprove}</label>
        <textarea
          className={styles.input}
          value={message}
          onChange={e => setMessage(e.target.value)}
          placeholder={translate.feedback.writeMessage}
          disabled={isLoading}
          required
        />
        <br />
        <label className={styles.label}>{translate.feedback.priority}</label>
        <select
          className={styles.select}
          value={priority}
          onChange={e => setPriority(e.target.value)}
          required
          disabled={isLoading}
        >
          <option value="" disabled>
            {translate.feedback.selectPriority}
          </option>
          <option value="low">{translate.feedback.low}</option>
          <option value="medium">{translate.feedback.medium}</option>
          <option value="high">{translate.feedback.high}</option>
        </select>
        <button type="submit" className={styles.button} disabled={isLoading}>
          {translate.feedback.submit}
        </button>
      </form>
    </Container>
  );
}

Feedback.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired
  }),
  match: PropTypes.shape({
    params: PropTypes.object.isRequired
  })
};
