import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  ChevronLeft,
  ChevronRight,
  Archive,
  Unarchive,
  ArrowDropDownOutlined,
  ThumbUp,
  ThumbUpOutlined,
  List,
  DeleteForever,
  Add
} from "@material-ui/icons";
import moment from "moment";
import { Link } from "react-router-dom";
import firebase from "firebase/app";
import DatePicker from "react-datepicker";
import styles from "./viewInformation.module.css";
import Container from "../../components/container";
import Answer from "../../components/answer";
import routes from "../../routes";
import { roles } from "../../utils";
import { StateContext } from "../../contexts/state";
import { menuData, postColors } from "../../constants";
import API from "../../api";
import { StatusContext } from "../../contexts/status";
import alarmNote from "../../assets/icons/alarm-note.png";

export default function ViewInformation(props) {
  const {
    history,
    location,
    match: {
      params: { id }
    }
  } = props;
  const informations = location?.state?.informations;
  const { updateErrorMessage, updateSuccessMessage } = useContext(StatusContext);
  const { user, role, channel } = useContext(StateContext);
  const [information, setInformation] = useState({});
  const [oldInformation, setOldInformation] = useState({});
  const [postColor, setPostColor] = useState(postColors[0].value);
  const [colorChanged, setColorChanged] = useState(false);
  const [comment, setComment] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isStatusOverflow, setIsStatusOverflow] = useState(false);
  const [index, setIndex] = useState(0);
  const [alarmDate, setAlarmDate] = useState(null);

  useEffect(() => {
    if (!informations.length) {
      history.push(routes.summary);
    }
    const _information = informations.find(i => i._id === id);
    if (!_information) {
      history.push(routes.summary);
    }
    const _index = informations.findIndex(i => i._id === id);
    setInformation(_information);
    setOldInformation(_information);
    setIndex(_index);
    setPostColor(_information.color);
    setColorChanged(false);
    firebase.database().ref("events").child("informations").child(_information._id).child(user._id).set(true);
    firebase.database().ref("events").child("comments").child(_information._id).child(user._id).set(true);
  }, []);

  const updateAnswer = (answer, value) => {
    const _information = { ...information };
    _information.answers = _information.answers.map(_answer => {
      if (_answer._id === answer) {
        return { ..._answer, answer: value };
      }
      return _answer;
    });
    setInformation(_information);
  };

  const onUpdateInformation = async (update = true) => {
    try {
      setIsSaving(true);
      window.onbeforeunload = function () {
        return "Post is being saved";
      };
      let _answers = information.answers.map(answer => ({ ...answer, question: answer.question._id }));
      const answers = await Promise.all(
        _answers.map(async answer => {
          if (!Array.isArray(answer.answer)) {
            return answer;
          }
          const _answer = await Promise.all(
            answer.answer.map(async file => {
              if (typeof file === "string") {
                return file;
              }
              const response = await API.file().upload(file);
              return response.data.url;
            })
          );
          return { ...answer, answer: _answer.join(";") };
        })
      );
      const _comments = [...information.comments];
      if (comment.trim()) {
        _comments.push({
          text: comment,
          sender: user._id
        });
      }
      const data = {
        answers,
        comments: _comments,
        color: postColor
      };
      const response = await API.information(id).update(data);
      if (update) {
        setInformation(response.data);
        setOldInformation(response.data);
        setColorChanged(false);
      }
      updateSuccessMessage("Saved");
      if (comment.trim()) {
        await firebase.database().ref("events").child("comments").child(information._id).set(null);
        await firebase.database().ref("events").child("comments").child(information._id).child(user._id).set(true);
      }
      setComment("");
      await firebase.database().ref("events").child("informations").child(id).set(null);
      await firebase.database().ref("events").child("informations").child(id).child(user._id).set(true);
      setIsSaving(false);
      window.onbeforeunload = function () {
        return null;
      };
    } catch (error) {
      setIsSaving(false);
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const onResolvedOptionSelect = async resolvedStatus => {
    try {
      let data = {
        resolvedStatus
      };
      if (!information.isResolved) {
        const _comments = [...information.comments];
        _comments.push({
          text: `Resolved by ${user.firstName} ${user.lastName}`,
          sender: user._id
        });
        data = {
          ...data,
          comments: _comments,
          isResolved: true,
          resolvedBy: user._id,
          resolvedAt: new Date().toISOString()
        };
      }
      const response = await API.information(id).update(data);
      setInformation(response.data);
      setOldInformation(response.data);
      setIsStatusOverflow(false);
      updateSuccessMessage("Saved");
      await firebase.database().ref("events").child("informations").child(id).set(null);
      await firebase.database().ref("events").child("informations").child(id).child(user._id).set(true);
    } catch (error) {
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const onCheckmarkPress = async () => {
    try {
      if (information.resolvedBy !== user._id && role !== roles.ADMIN) return updateErrorMessage("Access denied");
      let _comments = [...information.comments];
      let data = {};
      let message = "";
      if (!information.isResolved) {
        _comments.push({
          text: `Resolved by ${user.firstName} ${user.lastName}`,
          sender: user._id
        });
        data = {
          comments: _comments,
          isResolved: true,
          resolvedBy: user._id,
          resolvedAt: new Date().toISOString(),
          resolvedStatus: 0
        };
        message = "Resolved";
      } else {
        _comments = _comments
          .filter(_comment => !_comment.text.includes("Resolved by "))
          .map(_comment => ({ ..._comment, sender: _comment.sender._id }));
        data = {
          comments: _comments,
          isResolved: false,
          resolvedBy: null,
          resolvedAt: null,
          resolvedStatus: null
        };
        message = "Unresolved";
      }
      const response = await API.information(id).update(data);
      setInformation(response.data);
      setOldInformation(response.data);
      updateSuccessMessage(message);
      await firebase.database().ref("events").child("informations").child(id).set(null);
      await firebase.database().ref("events").child("informations").child(id).child(user._id).set(true);
    } catch (error) {
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const onArchive = async () => {
    try {
      const response = await API.information(information._id).update({
        deletedBy: user._id,
        deletedAt: moment().toISOString()
      });
      setInformation(response.data);
      setOldInformation(response.data);
      updateSuccessMessage("Archived");
    } catch (error) {
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const onUnarchive = async () => {
    try {
      const response = await API.information(information._id).update({
        deletedBy: null,
        deletedAt: null
      });
      setInformation(response.data);
      setOldInformation(response.data);
      updateSuccessMessage("Unarchived");
    } catch (error) {
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const onDelete = async () => {
    try {
      const isConfirm = window.confirm("Are you sure? This action can not be reversed");
      if (isConfirm) {
        await API.information(information._id).delete();
        history.push(routes.summary);
      }
    } catch (error) {
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const onCreateAlarm = async date => {
    try {
      await API.alert().new({ information: information._id, date, receiver: user._id });
      setAlarmDate(null);
      updateSuccessMessage("Alarm was set");
    } catch (error) {
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    onUpdateInformation();
  };

  const getAnswer = answer => {
    if (answer.question.type === "picture" && answer.answer && typeof answer.answer === "string") {
      return answer.answer.split(";");
    }
    return answer.answer || "";
  };

  const isEdited = JSON.stringify(information) === JSON.stringify(oldInformation) && !comment.trim() && !colorChanged;

  const getResolvedStatusColor = () => {
    const status = menuData.find(menu => menu.status === information.resolvedStatus);
    if (status && status.color) {
      return status.color;
    }
    return "unset";
  };

  const onMoveInformation = async (callback = () => {}) => {
    if (!isEdited) {
      const isConfirm = window.confirm(`Do you want to save the changes?`);
      if (isConfirm) {
        await onUpdateInformation(false);
      }
    }
    callback();
  };

  const onLastInformation = () => {
    const _information = informations[index - 1];
    history.replace(routes.post.replace(":id", _information._id), { informations });
    const _index = informations.findIndex(i => i._id === _information._id);
    setInformation(_information);
    setOldInformation(_information);
    setIndex(_index);
    setPostColor(_information.color);
    setColorChanged(false);
    firebase.database().ref("events").child("informations").child(_information._id).child(user._id).set(true);
    firebase.database().ref("events").child("comments").child(_information._id).child(user._id).set(true);
  };

  const onNextInformation = () => {
    const _information = informations[index + 1];
    history.replace(routes.post.replace(":id", _information._id), { informations });
    const _index = informations.findIndex(i => i._id === _information._id);
    setInformation(_information);
    setOldInformation(_information);
    setIndex(_index);
    setPostColor(_information.color);
    setColorChanged(false);
    firebase.database().ref("events").child("informations").child(_information._id).child(user._id).set(true);
    firebase.database().ref("events").child("comments").child(_information._id).child(user._id).set(true);
  };

  return (
    <Container {...props}>
      <div className={styles.container}>
        <div className={styles.containerWrapper}>
          <Link to={routes.home} className={styles.enterInformation}>
            <Add />
          </Link>
          <Link className={styles.summaryButton} to={routes.summary}>
            <List />
          </Link>
          <div className={styles.actionsContainer}>
            {information?.haveSubtasks && <Link>See subtasks</Link>}
            {!channel.isPublic && (
              <button style={{ color: getResolvedStatusColor() }} onClick={onCheckmarkPress}>
                {information.isResolved ? <ThumbUp /> : <ThumbUpOutlined />}
              </button>
            )}
            {!channel.isPublic && (
              <div className={styles.overflowContainer}>
                <button className={styles.overflowButton} onClick={() => setIsStatusOverflow(!isStatusOverflow)}>
                  <ArrowDropDownOutlined />
                </button>
                <div className={styles.overflowWrapper} style={{ display: isStatusOverflow ? "flex" : "none" }}>
                  {menuData.map((option, index) => (
                    <button
                      key={String(index)}
                      className={styles.overflowItem}
                      onClick={() => onResolvedOptionSelect(option.status)}
                    >
                      <div style={{ color: option.color, marginRight: "10px" }}>
                        <ThumbUp />
                      </div>
                      <span>{option.title}</span>
                    </button>
                  ))}
                </div>
              </div>
            )}
            <div className={styles.alertButton}>
              <DatePicker
                minDate={new Date()}
                showTimeSelect
                customInput={<img className={styles.alarmNoteIcon} src={alarmNote} alt="alarm_note" />}
                selected={alarmDate}
                onChange={(date, isDate) => {
                  if (!isDate && alarmDate) {
                    const _alarmDate = moment(
                      `${moment(alarmDate).format("Y-MM-DD")} ${moment(date).format("HH:mm:ss")}`,
                      "Y-MM-DD HH:mm:ss"
                    ).toDate();
                    setAlarmDate(_alarmDate);
                    onCreateAlarm(_alarmDate.toISOString());
                  } else {
                    setAlarmDate(date);
                  }
                }}
              />
            </div>
            {(role === roles.ADMIN || information.sender?._id === user._id) && information.deletedAt && information.deletedBy ? (
              <>
                <button onClick={onUnarchive}>
                  <Unarchive />
                </button>
                <button onClick={onDelete}>
                  <DeleteForever />
                </button>
              </>
            ) : (
              <button onClick={onArchive}>
                <Archive />
              </button>
            )}
          </div>
        </div>
        <div className={styles.mainContainer}>
          <div className={styles.arrowButtonContainer}>
            <button className={styles.arrowButton} onClick={() => onMoveInformation(onLastInformation)} disabled={index === 0}>
              <ChevronLeft fontSize="large" />
            </button>
          </div>
          <form className={styles.wrapper} onSubmit={onSubmit}>
            <h4 className={styles.identifier}>ID: #{information.identifier}</h4>
            {information?.answers?.map(answer => (
              <Answer
                {...answer.question}
                _id={answer._id}
                key={answer._id}
                updateAnswer={updateAnswer}
                answer={getAnswer(answer)}
                disabled={role !== roles.ADMIN || information?.sender?._id !== user._id}
              />
            ))}
            <div>
              <span className={styles.label}>Color</span>
              <div className={styles.colorsContainer}>
                {postColors.map(_postColor => (
                  <button
                    type="button"
                    key={_postColor.value}
                    className={styles.colorButton}
                    style={{
                      backgroundColor: _postColor.value,
                      borderColor: _postColor.value === postColor ? "black" : "white"
                    }}
                    onClick={() => {
                      setPostColor(_postColor.value);
                      setColorChanged(_postColor.value !== information.color);
                    }}
                  />
                ))}
              </div>
            </div>
            <br />
            <div>
              <span className={styles.label}>Comments</span>
              <div>
                {information?.comments?.map(_comment => (
                  <div key={_comment._id} className={styles.input} style={{ color: `#${_comment.sender._id.slice(-6)}` }}>
                    <b>{_comment.text}</b>
                    <p>
                      {_comment.sender.firstName} {_comment.sender.lastName}
                    </p>
                    <p>{moment(_comment.createdAt).format("YYYY-MM-DD hh:mm:ss A")}</p>
                  </div>
                ))}
              </div>
              <input
                type="text"
                placeholder="Write your comment here"
                className={styles.input}
                value={comment}
                onChange={e => setComment(e.target.value)}
              />
            </div>
            <br />
            <div>
              <span className={styles.label}>Post sent by</span>
              <div className={styles.input}>
                <b>{`${information?.sender?.firstName || ""} ${information?.sender?.lastName || ""}`}</b>
                <p>{moment(information.createdAt).format("YYYY-MM-DD hh:mm:ss A")}</p>
              </div>
            </div>
            <button type="submit" className={styles.submitButton} disabled={isEdited || isSaving}>
              Submit
            </button>
          </form>
          <div className={styles.arrowButtonContainer}>
            <button
              className={styles.arrowButton}
              onClick={() => onMoveInformation(onNextInformation)}
              disabled={index + 1 === informations.length}
            >
              <ChevronRight fontSize="large" />
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
}

ViewInformation.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    state: PropTypes.object
  }),
  match: PropTypes.shape({
    params: PropTypes.object.isRequired
  })
};
