import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { SortableContainer, SortableElement, arrayMove } from "react-sortable-hoc";
import styles from "./editChannel.module.css";
import Container from "../../components/container";
import { StateContext } from "../../contexts/state";
import { StatusContext } from "../../contexts/status";
import API from "../../api";
import Question from "../../components/question";
import shortid from "shortid";
import routes from "../../routes";
import translate from "../../translate";

const SortableItem = SortableElement(({ value: { value, index } }) => (
  <Question {...value} index={index} disabled isReorganizing />
));

const SortableList = SortableContainer(({ items }) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem key={value.id} index={index} value={{ value, index }} />
      ))}
    </div>
  );
});

export default function EditChannel(props) {
  const { history } = props;
  const { updateErrorMessage, updateWarningMessage, updateSuccessMessage } = useContext(StatusContext);
  const { channel, user, rules, refreshChannels, refreshChannel } = useContext(StateContext);
  const [name, setName] = useState("");
  const [questions, setQuestions] = useState([]);
  const [lifeSpan, setLifeSpan] = useState("");
  const [isReorganize, setIsReorganize] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (channel._id) {
      setName(channel.name);
      setQuestions(channel.questions.map((question) => ({ ...question, id: question._id })));
      setLifeSpan(channel.lifeSpan);
    }
  }, [channel]);

  const addNewQuestion = () => {
    const _questions = [...questions];
    if (!user.isPaid) {
      const maxQuestions = user.isPremium ? rules.premium.max_questions : rules.free.max_questions;
      if (_questions.length >= maxQuestions) {
        API.premiumEvents().new({ type: "max_questions" });
        updateWarningMessage("Upgrade your account. You have reached the maximum amount of fields for this channel");
        return;
      }
    }
    _questions.push({ id: shortid.generate(), text: "", type: "", isMandatory: false, dropdowns: [] });
    setQuestions(_questions);
  };

  const updateField = (id, text, type, isMandatory, dropdowns) => {
    let _questions = [...questions];
    _questions = _questions.map((_question) => {
      if (_question.id === id) {
        return { ..._question, id, text, type, isMandatory, dropdowns };
      }
      return _question;
    });
    setQuestions(_questions);
  };

  const deleteField = (id) => {
    let _questions = [...questions];
    if (_questions.length === 1) {
      updateWarningMessage("You need to have at least one field");
      return;
    }
    _questions = _questions.filter((_question) => _question.id !== id);
    setQuestions(_questions);
  };

  const onDeleteChannel = async () => {
    try {
      const isConfirm = window.confirm(`This action can not be reversed. Are you sure you want to delete ${channel.name}?`);
      if (isConfirm) {
        setIsLoading(true);
        await API.channel(channel._id).delete();
        setIsLoading(false);
        window.location.href = "/";
      }
    } catch (error) {
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const onEditChannel = async () => {
    try {
      setIsLoading(true);
      let _questions = [...questions];
      _questions = _questions.map((question) => {
        const _question = { ...question };
        if (_question.id.length === 24) _question._id = question.id;
        return _question;
      });
      await API.channel(channel._id).update(name, _questions, lifeSpan);
      await refreshChannel();
      await refreshChannels();
      updateSuccessMessage(translate.editChannel.confirm);
      history.push(routes.home);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    onEditChannel();
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setQuestions(arrayMove([...questions], oldIndex, newIndex));
  };

  return (
    <Container {...props}>
      <h3 className={styles.title}>{translate.newChannel.title}</h3>
      <form className={styles.form} onSubmit={onSubmit}>
        <label className={styles.label}>{translate.newChannel.name}</label>
        <input
          type="text"
          placeholder={translate.newChannel.name}
          className={styles.input}
          value={name}
          onChange={(e) => setName(e.target.value)}
          disabled={isLoading}
          required
        />
        {isReorganize ? (
          <SortableList items={questions} onSortEnd={onSortEnd} />
        ) : (
          questions.map((question, index) => (
            <Question
              key={question.id}
              {...question}
              index={index}
              updateField={updateField}
              deleteField={deleteField}
              disabled={isLoading}
            />
          ))
        )}
        <button type="button" className={styles.addMoreButton} disabled={isLoading} onClick={addNewQuestion}>
          {translate.newChannel.add}
        </button>
        {user.isPaid && (
          <>
            <label className={styles.label}>{translate.newChannel.lifeSpan}</label>
            <input
              type="text"
              placeholder={translate.newChannel.lifeSpan}
              className={styles.input}
              value={lifeSpan}
              onChange={(e) => setLifeSpan(e.target.value)}
              disabled={isLoading}
            />
          </>
        )}
        <div className={styles.buttonsContainer}>
          <button type="button" className={styles.deleteButton} disabled={isLoading} onClick={onDeleteChannel}>
            {translate.newChannel.delete}
          </button>
          <button
            type="button"
            className={styles.reorganizeButton}
            disabled={isLoading}
            onClick={() => setIsReorganize(!isReorganize)}
          >
            {isReorganize ? translate.newChannel.edit : translate.newChannel.reorganize} {translate.newChannel.fields}
          </button>
          <button type="submit" className={styles.submitButton} disabled={isLoading || isReorganize}>
            {translate.newChannel.submit}
          </button>
        </div>
      </form>
    </Container>
  );
}

EditChannel.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }),
};
