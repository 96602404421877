import { createContext } from "react";
import { state } from "../constants";

const { WORKSPACES, CHANNELS, WORKSPACE, CHANNEL, USER, TOKEN, ROLE, RULES, IS_LOGGED_IN, STEP } = state;

const workspace = {
  admins: [""],
  team: [""],
  _id: "",
  name: "",
  createdBy: {
    _id: "",
    firstName: "",
    lastName: "",
  },
  type: "",
  createdAt: "",
  updatedAt: "",
};

const channel = {
  questions: [
    {
      isMandatory: false,
      dropdowns: [""],
      _id: "",
      text: "",
      type: "",
    },
  ],
  admins: [
    {
      _id: "",
      firstName: "",
      lastName: "",
      email: "",
      createdAt: "",
      updatedAt: "",
      lastLoggedIn: "",
    },
  ],
  receivers: [
    {
      _id: "",
      firstName: "",
      lastName: "",
      email: "",
      createdAt: "",
      updatedAt: "",
      lastLoggedIn: "",
    },
  ],
  senders: [
    {
      _id: "",
      firstName: "",
      lastName: "",
      email: "",
      createdAt: "",
      updatedAt: "",
      lastLoggedIn: "",
    },
  ],
  team: [
    {
      _id: "",
      firstName: "",
      lastName: "",
      email: "",
      createdAt: "",
      updatedAt: "",
      lastLoggedIn: "",
    },
  ],
  _id: "",
  name: "",
  workspace: "",
  createdBy: {
    _id: "",
    firstName: "",
    lastName: "",
    email: "",
    createdAt: "",
    updatedAt: "",
    lastLoggedIn: "",
  },
  lifeSpan: 0,
  createdAt: "",
  updatedAt: "",
  isOpen: false,
};

const rules = {
  free: {
    max_workspaces: 0,
    max_channels: 0,
    max_dropdown_options: 0,
    max_questions: 0,
    max_posts_month: 0,
    export_to_excel: false,
    days_deleted_post: 0,
    max_photos: 0,
    max_admins_per_channel: 0,
  },
  premium: {
    max_workspaces: 0,
    max_channels: 0,
    max_dropdown_options: 0,
    max_questions: 0,
    max_posts_month: 0,
    export_to_excel: false,
    days_deleted_post: 0,
    max_photos: 0,
    max_admins_per_channel: 0,
  },
  gold: {
    max_workspaces: 0,
    max_channels: 0,
    max_dropdown_options: 0,
    max_questions: 0,
    max_posts_month: 0,
    export_to_excel: false,
    days_deleted_post: 0,
    max_photos: 0,
    max_admins_per_channel: 0,
  },
};

const initialState = {
  workspaces: [workspace],
  channels: [channel],
  workspace,
  channel,
  user: {
    isVerified: false,
    isPremium: false,
    isPaid: false,
    isAdmin: false,
    isNotifications: false,
    isSoundOrVibrate: false,
    isPostsNotifications: false,
    isAlertsNotifications: false,
    welcomeEmailSent: false,
    _id: "",
    firstName: "",
    lastName: "",
    email: "",
    createdAt: "",
    updatedAt: "",
    lastLoggedIn: "",
    showSubscriptionForm: false,
    hasTokens: false,
  },
  token: "",
  role: "",
  rules,
  isLoggedIn: false,
  step: 0,
};

const StateContext = createContext({
  updateWorkspaces: (workspaces = []) => {},
  updateChannels: (channels = []) => {},
  updateWorkspace: (workspace = {}) => {},
  updateChannel: (channel = {}) => {},
  updateUser: (user = {}) => {},
  updateToken: (token = "") => {},
  updateRole: (role = "") => {},
  updateRules: (rules = {}) => {},
  updateIsLoggedIn: (isLoggedIn = false) => {},
  updateStep: (step = 0) => {},
  refreshWorkspaces: async () => {},
  refreshChannels: async () => {},
  refreshChannel: async () => {},
  ...initialState,
});

const stateReducer = (prevState, action) => {
  switch (action.type) {
    case WORKSPACES:
      return {
        ...prevState,
        workspaces: action.workspaces,
      };
    case CHANNELS:
      return {
        ...prevState,
        channels: action.channels,
      };
    case WORKSPACE:
      localStorage.setItem(WORKSPACE, action.workspace._id);
      return {
        ...prevState,
        workspace: action.workspace,
      };
    case CHANNEL:
      localStorage.setItem(CHANNEL, action.channel._id);
      return {
        ...prevState,
        channel: action.channel,
      };
    case USER:
      return {
        ...prevState,
        user: action.user,
      };
    case TOKEN:
      return {
        ...prevState,
        token: action.token,
      };
    case ROLE:
      return {
        ...prevState,
        role: action.role,
      };
    case RULES:
      return {
        ...prevState,
        rules: action.rules,
      };
    case STEP:
      return {
        ...prevState,
        step: action.step,
      };
    case IS_LOGGED_IN:
      if (action.isLoggedIn) {
        return {
          ...prevState,
          isLoggedIn: true,
        };
      } else {
        localStorage.clear();
        return {
          ...prevState,
          ...initialState,
        };
      }
    default:
      return prevState;
  }
};

export { initialState, StateContext, stateReducer };
