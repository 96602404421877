import React, { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { List } from "@material-ui/icons";
import firebase from "firebase/app";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { capitalize } from "@material-ui/core";
import styles from "./home.module.css";
import Container from "../../components/container";
import Answer from "../../components/answer";
import { StateContext } from "../../contexts/state";
import routes from "../../routes";
import { StatusContext } from "../../contexts/status";
import API from "../../api";
import alarmNote from "../../assets/icons/alarm-note.png";
import { postColors } from "../../constants";
import translate from "../../translate";

export default function Home(props) {
  const { history } = props;
  const { updateErrorMessage, updateSuccessMessage, updateWarningMessage } = useContext(StatusContext);
  const { channel, user, updateStep } = useContext(StateContext);
  const [isLoading, setIsLoading] = useState(false);
  const [postColor, setPostColor] = useState(postColors[0].value);
  const [lifeSpan, setLifeSpan] = useState("");
  const [answers, setAnswers] = useState({});
  const [alarmDate, setAlarmDate] = useState(null);
  const [reminderDate, setReminderDate] = useState(null);
  const [reminderTeamDate, setReminderTeamDate] = useState(null);
  const [reminderPeriod, setReminderPeriod] = useState("");
  const [reminderPeriodDate, setReminderPeriodDate] = useState(null);
  const [reminderResponsibleDate, setReminderResponsibleDate] = useState(null);

  useEffect(() => {
    if (!user.hasTokens && (reminderDate || reminderTeamDate || reminderPeriodDate || reminderResponsibleDate)) {
      setReminderDate(null);
      setReminderTeamDate(null);
      setReminderPeriodDate(null);
      setReminderResponsibleDate(null);
      updateWarningMessage("You must download the app");
    }
  }, [user.hasTokens, reminderDate, reminderTeamDate, reminderPeriodDate, reminderResponsibleDate]);

  const clearAnswers = useCallback(() => {
    const _answers = channel.questions.map((question) => [question._id, { question, answer: "" }]);
    setAnswers(Object.fromEntries(_answers));
    setLifeSpan(user.isPaid ? channel.lifeSpan : "");
    setPostColor(postColors[0].value);
    setAlarmDate(null);
    setReminderDate(null);
    setReminderTeamDate(null);
    setReminderPeriod("");
    setReminderPeriodDate(null);
    setReminderResponsibleDate(null);
  }, [channel]);

  useEffect(() => {
    if (channel._id) {
      clearAnswers();
    }
  }, [channel, clearAnswers]);

  const updateAnswer = (id, value) => {
    const _answers = { ...answers };
    _answers[id] = {
      ..._answers[id],
      answer: value,
    };
    setAnswers(_answers);
  };

  const sendImages = async () => {
    const _answers = await Promise.all(
      Object.values(answers).map(async (answer) => {
        if ((answer.question.type === "picture" || answer.question.type === "video") && answer.answer) {
          const _answer = { ...answer };
          const links = await Promise.all(
            _answer.answer.map(async (file) => {
              const response = await API.file().upload(file);
              return response.data.url;
            })
          );
          _answer.answer = links.join(";");
          return _answer;
        }
        return answer;
      })
    );
    return _answers;
  };

  const onCreatePost = async () => {
    try {
      setIsLoading(true);
      const _answers = await sendImages();
      const date = moment().toISOString();
      const data = {
        answers: Object.values(_answers),
        sender: user._id,
        date,
        channel: channel._id,
        lifeSpan: lifeSpan && user.isPaid ? moment().add(lifeSpan, "days").toISOString() : null,
        color: postColor,
      };
      const _information = await API.information().new(data);
      if (alarmDate) {
        await API.alert().new({ information: _information.data._id, date: alarmDate?.toISOString(), receiver: user._id });
      }
      if (reminderDate) {
        await API.alert().new({
          information: _information.data._id,
          date: reminderDate.toISOString(),
          receiver: user._id,
          type: "personal",
        });
      }
      if (reminderTeamDate) {
        await API.alert().new({
          information: _information.data._id,
          date: reminderTeamDate.toISOString(),
          receiver: user._id,
          type: "team",
        });
      }
      if (reminderPeriod && reminderPeriodDate) {
        await API.alert().new({
          information: _information.data._id,
          date: reminderPeriodDate.toISOString(),
          receiver: user._id,
          type: "period",
          period: reminderPeriod,
        });
      }
      if (reminderResponsibleDate) {
        await API.alert().new({
          information: _information.data._id,
          date: reminderResponsibleDate.toISOString(),
          receiver: user._id,
          type: "responsible",
        });
      }
      firebase.database().ref("events").child("informations").child(_information.data._id).child(user._id).set(true);
      clearAnswers();
      updateSuccessMessage(translate.home.sent);
      setIsLoading(false);
      if (!user.lastLoggedIn) {
        updateStep(3);
        history.push(routes.tableView);
      }
    } catch (error) {
      setIsLoading(false);
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    onCreatePost();
  };

  if (!channel?._id) {
    return (
      <Container {...props}>
        <div></div>
      </Container>
    );
  }

  const periods = [
    {
      label: translate.reminders.daily,
      value: "daily",
    },
    {
      label: translate.reminders.everyotherday,
      value: "everyotherday",
    },
    {
      label: translate.reminders.weekly,
      value: "weekly",
    },
    {
      label: translate.reminders.monthly,
      value: "monthly",
    },
    {
      label: translate.reminders.onceayear,
      value: "onceayear",
    },
  ];

  return (
    <Container {...props}>
      <div className={styles.header}>
        <h3 className={styles.title}>{translate.home.title}</h3>
      </div>
      <form className={styles.form} onSubmit={onSubmit}>
        {channel.questions &&
          channel.questions.map((question) => (
            <Answer
              {...question}
              key={question._id}
              updateAnswer={updateAnswer}
              answer={answers[question._id]?.answer || ""}
              disabled={isLoading}
            />
          ))}
        {user.isPaid && (
          <>
            <label className={styles.label}>{translate.home.lifeSpan}</label>
            <input
              type="text"
              placeholder={translate.home.lifeSpan}
              className={styles.input}
              value={lifeSpan}
              onChange={(e) => setLifeSpan(e.target.value)}
              disabled={isLoading}
            />
          </>
        )}
        <br />
        <DatePicker
          minDate={new Date()}
          selected={reminderDate}
          showTimeSelect
          customInput={
            <div className={styles.checkbox}>
              <input
                type="checkbox"
                checked={Boolean(reminderDate)}
                onChange={(e) => {
                  if (!e.target.checked) {
                    setReminderDate(null);
                  }
                }}
              />
              <span>
                {translate.home.reminderAt}: {reminderDate ? moment(reminderDate).format("Y-MM-DD h:mm A") : translate.home.none}
              </span>
            </div>
          }
          onChange={setReminderDate}
          disabled={isLoading}
        />
        {user.isPaid && (
          <DatePicker
            minDate={new Date()}
            selected={reminderTeamDate}
            showTimeSelect
            customInput={
              <div className={styles.checkbox}>
                <input
                  type="checkbox"
                  checked={Boolean(reminderTeamDate)}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      setReminderTeamDate(null);
                    }
                  }}
                />
                <span>
                  {translate.home.reminderTeam}:{" "}
                  {reminderTeamDate ? moment(reminderTeamDate).format("Y-MM-DD h:mm A") : translate.home.none}
                </span>
              </div>
            }
            onChange={setReminderTeamDate}
            disabled={isLoading}
          />
        )}
        <div className={styles.checkbox}>
          <input
            type="checkbox"
            checked={Boolean(reminderPeriod)}
            onChange={(e) => {
              if (!e.target.checked) {
                setReminderPeriod("");
              }
            }}
          />
          <span>{translate.home.reminderToMe}: </span>
          <select className={styles.selectPeriod} value={reminderPeriod} onChange={(e) => setReminderPeriod(e.target.value)}>
            <option value="">{capitalize(translate.home.none)}</option>
            {periods.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {Boolean(reminderPeriod) && (
            <DatePicker
              minDate={new Date()}
              selected={reminderPeriodDate}
              showTimeSelect
              customInput={
                <span>
                  {reminderPeriodDate ? (
                    moment(reminderPeriodDate).format("Y-MM-DD h:mm A")
                  ) : (
                    <b>
                      <u>{translate.home.none}</u>
                    </b>
                  )}
                </span>
              }
              onChange={setReminderPeriodDate}
              disabled={isLoading}
              wrapperClassName={styles.datePicker}
            />
          )}
        </div>
        {user.isPaid && (
          <DatePicker
            minDate={new Date()}
            selected={reminderResponsibleDate}
            showTimeSelect
            customInput={
              <div className={styles.checkbox}>
                <input
                  type="checkbox"
                  checked={Boolean(reminderResponsibleDate)}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      setReminderResponsibleDate(null);
                    }
                  }}
                />
                <span>
                  {translate.home.reminderTeam}:{" "}
                  {reminderResponsibleDate ? moment(reminderResponsibleDate).format("Y-MM-DD h:mm A") : translate.home.none}
                </span>
              </div>
            }
            onChange={setReminderResponsibleDate}
            disabled={isLoading}
          />
        )}
        <br />
        <div>
          <span className={styles.label}>Color</span>
          <div className={styles.colorsContainer}>
            {postColors.map((_postColor) => (
              <button
                type="button"
                key={_postColor.value}
                className={styles.colorButton}
                style={{
                  backgroundColor: _postColor.value,
                  borderColor: _postColor.value === postColor ? "black" : "white",
                }}
                onClick={() => setPostColor(_postColor.value)}
              />
            ))}
          </div>
        </div>
        <div className={styles.actions}>
          <div className={styles.summaryButtonContainer}>
            <Link className={styles.summaryButton} to={routes.summary}>
              <List />
            </Link>
            <div className={styles.alertButton}>
              <DatePicker
                minDate={new Date()}
                showTimeSelect
                customInput={<img className={styles.alarmNoteIcon} src={alarmNote} alt="alarm_note" />}
                selected={alarmDate}
                onChange={(date, isDate) => {
                  if (!isDate && alarmDate) {
                    setAlarmDate(
                      moment(
                        `${moment(alarmDate).format("Y-MM-DD")} ${moment(date).format("HH:mm:ss")}`,
                        "Y-MM-DD HH:mm:ss"
                      ).toDate()
                    );
                  } else {
                    setAlarmDate(date);
                  }
                }}
                disabled={isLoading}
              />
            </div>
          </div>
          <button type="submit" className={styles.button} disabled={isLoading}>
            {translate.home.submit}
          </button>
        </div>
      </form>
    </Container>
  );
}

Home.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }),
};
