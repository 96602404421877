import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./container.module.css";
import Sidebar from "../sidebar";
import { StateContext } from "../../contexts/state";
import translate, { locale } from "../../translate";
import table1 from "../../assets/images/table-1.png";
import table2 from "../../assets/images/table-2.png";
import table2Es from "../../assets/images/table-2-es.png";
import table3 from "../../assets/images/table-3.png";
import table3Es from "../../assets/images/table-3-es.png";
import API from "../../api";

function Modal(props) {
  const { children, isOpen } = props;

  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.modalContainer}>
      <div className={styles.modal}>{children}</div>
    </div>
  );
}

export default function Container(props) {
  const { children, noScroll } = props;
  const state = useContext(StateContext);
  const [isModalVisible, setIsModalVisible] = useState(true);

  useEffect(() => {
    setIsModalVisible(true);
  }, [state.step]);

  const refreshUser = async () => {
    try {
      let user = await API.user().identify();
      user = user.data;
      state.updateUser(user);
    } catch (error) {}
  };

  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <Sidebar {...props} />
      </div>
      <div
        className={classNames(styles.main, {
          [styles.noScroll]: noScroll,
        })}
      >
        {children}
        {state.isLoggedIn && !state.user.lastLoggedIn && (
          <>
            <Modal isOpen={state.step === 0 && isModalVisible}>
              <center>
                <span>{translate.mainTab.letsCreate}</span>
              </center>
              <center>
                <span>{translate.mainTab.pleaseClick}</span> <b>"{translate.mainTab.createCustomTable}"</b>
              </center>
              <br />
              <img className="modal-image" src={table1} alt="table1" />
              <br />
            </Modal>
            <Modal isOpen={state.step === 1 && isModalVisible}>
              <center>
                <span>{translate.mainTab.letsDefine}</span>
              </center>
              <br />
              <img className="modal-image" src={locale === "es" ? table2Es : table2} alt="table2" />
              <br />
              <button className="modal-button" onClick={() => setIsModalVisible(false)}>
                {translate.mainTab.next}
              </button>
            </Modal>
            <Modal isOpen={state.step === 2 && isModalVisible}>
              <center>
                <span>{translate.mainTab.letsPut}</span>
              </center>
              <br />
              <img className="modal-image" src={locale === "es" ? table3Es : table3} alt="table3" />
              <br />
              <button className="modal-button" onClick={() => setIsModalVisible(false)}>
                {translate.mainTab.next}
              </button>
            </Modal>
            <Modal isOpen={state.step === 3 && isModalVisible}>
              <center>
                <span>{translate.mainTab.thisIsTheTableView}</span>
              </center>
              <br />
              <button
                className="modal-button"
                onClick={() => {
                  setIsModalVisible(false);
                  refreshUser();
                }}
              >
                {translate.mainTab.close}
              </button>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
}

Container.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }),
  children: PropTypes.node.isRequired,
  noScroll: PropTypes.bool,
};

Container.defaultProps = {
  noScroll: false,
};
