import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styles from "./signUp.module.css";
import { StatusContext } from "../../contexts/status";
import routes from "../../routes";
import API from "../../api";
import translate, { locale } from "../../translate";

export default function SignUp(props) {
  const { history } = props;
  const { updateErrorMessage, updateSuccessMessage } = useContext(StatusContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onSignUp = async () => {
    try {
      setIsLoading(true);
      if (password !== confirmPassword) throw new Error("Passwords do not match");
      await API.user().signup(firstName, lastName, email, password, locale);
      updateSuccessMessage(translate.signUp.confirm);
      history.push(routes.signIn);
    } catch (error) {
      setIsLoading(false);
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    onSignUp();
  };

  return (
    <div className={styles.container}>
      <h2>Register</h2>
      <br />
      <br />
      <form onSubmit={onSubmit}>
        <h4>First Name</h4>
        <input
          className={styles.input}
          type="text"
          placeholder="John"
          autoCapitalize="on"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          disabled={isLoading}
          required
        />
        <h4>Last Name</h4>
        <input
          className={styles.input}
          type="text"
          placeholder="Doe"
          autoCapitalize="on"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          disabled={isLoading}
          required
        />
        <h4>Email</h4>
        <input
          className={styles.input}
          type="email"
          placeholder="john.doe@example.com"
          autoCapitalize="off"
          autoComplete="off"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={isLoading}
          required
        />
        <h4>Password</h4>
        <input
          className={styles.input}
          type="password"
          placeholder="********"
          autoCapitalize="off"
          autoComplete="off"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={isLoading}
          required
        />
        <h4>Confirm Password</h4>
        <input
          className={styles.input}
          type="password"
          placeholder="********"
          autoCapitalize="off"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          disabled={isLoading}
          required
        />
        <button type="submit" className={styles.button} disabled={isLoading}>
          Register
        </button>
      </form>
      <br />
      <div className={styles.actions}>
        <span>Already have an account?</span>
        <Link to={routes.signIn}>
          <button className={styles.buttonLink} disabled={isLoading}>
            Sign in
          </button>
        </Link>
      </div>
    </div>
  );
}

SignUp.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }),
};
