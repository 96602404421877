import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { StatusContext } from "../../contexts/status";
import styles from "./signIn.module.css";
import logo from "../../assets/images/logo.png";
import API from "../../api";
import routes from "../../routes";
import { state } from "../../constants";
import translate, { locale } from "../../translate";
import { StateContext } from "../../contexts/state";

const { WORKSPACE, CHANNEL, TOKEN } = state;

export default function SignIn(props) {
  const {
    updateUser,
    updateWorkspaces,
    updateChannels,
    updateRules,
    updateWorkspace,
    updateChannel,
    updateIsLoggedIn,
    ...state
  } = useContext(StateContext);
  const { updateErrorMessage } = useContext(StatusContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onSignIn = async () => {
    try {
      setIsLoading(true);
      const response = await API.user().signin(email, password, "web");
      const { token } = response.data;
      localStorage.setItem(TOKEN, token);
      const actions = [API.user().identify, API.workspace().list, API.channel().list, API.rule().list];
      const [user, workspaces, channels, rules] = await Promise.all(
        actions.map(async (action) => {
          const value = await action();
          return value.data;
        })
      );
      updateWorkspaces(workspaces);
      updateChannels(channels);
      const lastWorkspace = localStorage.getItem(WORKSPACE);
      const lastChannel = localStorage.getItem(CHANNEL);
      let workspace =
        workspaces.find((_workspace) => _workspace._id === lastWorkspace) ||
        workspaces.find((_workspace) => _workspace._id === state.workspace._id);
      if (!workspace) {
        [workspace] = workspaces;
      }
      updateWorkspace(workspace);
      let channel =
        channels.find((_channel) => _channel._id === lastChannel) ||
        channels.find((_channel) => _channel.workspace === workspace._id);
      if (!channel) {
        channel = {};
      }
      if (!user.language) {
        API.user().updateLanguage(locale);
      } else {
        localStorage.setItem("locale", user.language);
      }
      updateChannel(channel);
      updateUser(user);
      updateRules(rules);
      updateIsLoggedIn(true);
    } catch (error) {
      setIsLoading(false);
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    onSignIn();
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img className={styles.logo} src={logo} alt="logo" />
        <h1 className={styles.title}>Tabular Plan</h1>
      </div>
      <h2>{translate.signIn.title}</h2>
      <span className={styles.message}>{translate.signIn.message}</span>
      <br />
      <br />
      <form onSubmit={onSubmit}>
        <h4>{translate.signIn.email}</h4>
        <input
          className={styles.input}
          type="email"
          placeholder="john.doe@example.com"
          autoCapitalize="off"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={isLoading}
          required
        />
        <h4>{translate.signIn.password}</h4>
        <input
          className={styles.input}
          type="password"
          placeholder="********"
          autoCapitalize="off"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={isLoading}
          required
        />
        <button type="submit" className={styles.button} disabled={isLoading}>
          {translate.signIn.submit}
        </button>
      </form>
      <br />
      <div className={styles.actions}>
        <Link to={routes.forgotPassword}>
          <button className={styles.forgotPasswordButton} disabled={isLoading}>
            {translate.signIn.forgotPassword}
          </button>
        </Link>
        <Link to={routes.signUp}>
          <button className={styles.registerButton} disabled={isLoading}>
            {translate.signIn.register}
          </button>
        </Link>
      </div>
    </div>
  );
}

SignIn.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }),
};
