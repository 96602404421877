import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { StateContext, stateReducer, initialState } from "./contexts/state";
import { StatusContext, statusReducer, initialStatus } from "./contexts/status";
import { state, status } from "./constants";
import { getRole } from "./utils";
import Toast from "./components/toast";
import splash from "./assets/images/splash.gif";

import API from "./api";

import routes from "./routes";
import { locale } from "./translate";

import SignInPage from "./pages/signIn";
import SignUpPage from "./pages/signUp";
import ForgotPasswordPage from "./pages/forgotPassword";

import HomePage from "./pages/home";
import NewWorkspacePage from "./pages/newWorkspace";
import NewChannelPage from "./pages/newChannel";
import SummaryPage from "./pages/summary";
import CalendarPage from "./pages/calendar";
import MembersPage from "./pages/members";
import AddMemberPage from "./pages/addMember";
import FeedbackPage from "./pages/feedback";
import ViewInformationPage from "./pages/viewInformation";
import EditChannelPage from "./pages/editChannel";
import TableViewPage from "./pages/tableView";
import TemplatesPage from "./pages/templates";
import MyAccountPage from "./pages/myAccount";
import InvitationPage from "./pages/invitation";

const { WORKSPACES, CHANNELS, WORKSPACE, CHANNEL, USER, TOKEN, ROLE, RULES, IS_LOGGED_IN, STEP } = state;
const { IS_LOADING, ERROR_MESSAGE, SUCCESS_MESSAGE, INFO_MESSAGE, WARNING_MESSAGE } = status;

export default function App() {
  const [state, dispatchState] = useReducer(stateReducer, {
    ...initialState,
    workspaces: [],
    channels: [],
    workspace: {},
    channel: {},
  });
  const [status, dispatchStatus] = useReducer(statusReducer, initialStatus);

  const stateContext = useMemo(
    () => ({
      updateWorkspaces: (workspaces = []) => dispatchState({ type: WORKSPACES, workspaces }),
      updateChannels: (channels = []) => dispatchState({ type: CHANNELS, channels }),
      updateWorkspace: (workspace = {}) => dispatchState({ type: WORKSPACE, workspace }),
      updateChannel: (channel = {}) => dispatchState({ type: CHANNEL, channel }),
      updateUser: (user) => dispatchState({ type: USER, user }),
      updateToken: (token) => dispatchState({ type: TOKEN, token }),
      updateRules: (rules) => dispatchState({ type: RULES, rules }),
      updateIsLoggedIn: (isLoggedIn) => dispatchState({ type: IS_LOGGED_IN, isLoggedIn }),
      updateStep: (step) => dispatchState({ type: STEP, step }),
      refreshWorkspaces: async () => {
        const response = await API.workspace().list();
        dispatchState({ type: WORKSPACES, workspaces: response.data });
      },
      refreshChannels: async () => {
        const response = await API.channel().list();
        dispatchState({ type: CHANNELS, channels: response.data });
      },
      refreshChannel: async () => {
        const response = await API.channel(state.channel._id).get();
        dispatchState({ type: CHANNEL, channel: response.data });
      },
      ...state,
    }),
    [state]
  );

  const statusContext = useMemo(
    () => ({
      updateIsLoading: (isLoading) => dispatchStatus({ type: IS_LOADING, isLoading }),
      updateErrorMessage: (errorMessage) => dispatchStatus({ type: ERROR_MESSAGE, errorMessage }),
      updateSuccessMessage: (successMessage) => dispatchStatus({ type: SUCCESS_MESSAGE, successMessage }),
      updateInfoMessage: (infoMessage) => dispatchStatus({ type: INFO_MESSAGE, infoMessage }),
      updateWarningMessage: (warningMessage) => dispatchStatus({ type: WARNING_MESSAGE, warningMessage }),
    }),
    []
  );

  const initialize = useCallback(async () => {
    try {
      const token = localStorage.getItem(TOKEN);
      if (token) {
        const actions = [API.user().identify, API.workspace().list, API.channel().list, API.rule().list];
        const [user, workspaces, channels, rules] = await Promise.all(
          actions.map(async (action) => {
            const value = await action();
            return value.data;
          })
        );
        if (!user.language) {
          API.user().updateLanguage(locale);
        } else {
          localStorage.setItem("locale", user.language);
        }
        dispatchState({ type: WORKSPACES, workspaces });
        dispatchState({ type: CHANNELS, channels });
        const lastWorkspace = localStorage.getItem(WORKSPACE);
        const lastChannel = localStorage.getItem(CHANNEL);
        let workspace =
          workspaces.find((_workspace) => _workspace._id === lastWorkspace) ||
          workspaces.find((_workspace) => _workspace._id === state.workspace._id);
        if (!workspace) {
          [workspace] = workspaces;
        }
        dispatchState({ type: WORKSPACE, workspace });
        let channel =
          channels.find((_channel) => _channel._id === lastChannel) ||
          channels.find((_channel) => _channel.workspace === workspace._id);
        if (!channel) {
          channel = {};
        }
        dispatchState({ type: CHANNEL, channel });
        dispatchState({ type: USER, user });
        dispatchState({ type: RULES, rules });
        dispatchState({ type: IS_LOGGED_IN, isLoggedIn: true });
      }
    } catch (error) {
      dispatchStatus({ type: ERROR_MESSAGE, errorMessage: API.getErrorMessage(error) });
    }
    dispatchStatus({ type: IS_LOADING, isLoading: false });
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (state?.channel?._id && state?.user?._id) {
      dispatchState({ type: ROLE, role: getRole(state.channel, state.user) });
    }
  }, [state.channel, state.user]);

  useEffect(() => {
    if (state?.channel?._id) {
      localStorage.removeItem("order");
      localStorage.removeItem("orderdir");
    }
  }, [state.channel]);

  if (status.isLoading) {
    return (
      <div id="splash-container">
        <img src={splash} alt="splash" id="splash" />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <ToastProvider autoDismiss placement="top-center">
        <StateContext.Provider value={stateContext}>
          <StatusContext.Provider value={statusContext}>
            {state.isLoggedIn ? (
              <Switch>
                <Route path={routes.home} component={HomePage} exact />
                <Route path={routes.newWorkspace} component={NewWorkspacePage} exact />
                <Route path={routes.newChannel} component={NewChannelPage} exact />
                <Route path={routes.summary} component={SummaryPage} exact />
                <Route path={routes.calendar} component={CalendarPage} exact />
                <Route path={routes.members} component={MembersPage} exact />
                <Route path={routes.addMember} component={AddMemberPage} exact />
                <Route path={routes.feedback} component={FeedbackPage} exact />
                <Route path={routes.post} component={ViewInformationPage} exact />
                <Route path={routes.editChannel} component={EditChannelPage} exact />
                <Route path={routes.tableView} component={TableViewPage} exact />
                <Route path={routes.templates} component={TemplatesPage} exact />
                <Route path={routes.myAccount} component={MyAccountPage} exact />
                <Route path={routes.invitation} component={InvitationPage} exact />
                <Redirect to={routes.home} />
              </Switch>
            ) : (
              <Switch>
                <Route path={routes.signIn} component={SignInPage} exact />
                <Route path={routes.signUp} component={SignUpPage} exact />
                <Route path={routes.forgotPassword} component={ForgotPasswordPage} exact />
                <Route path={routes.invitation} component={InvitationPage} exact />
                <Redirect to={routes.signIn} />
              </Switch>
            )}
            <Toast {...status} />
          </StatusContext.Provider>
        </StateContext.Provider>
      </ToastProvider>
    </BrowserRouter>
  );
}
