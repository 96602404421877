import moment from "moment";
import hexRgb from "hex-rgb";

function formatAnswer(answer) {
  try {
    if (answer.answer) {
      switch (answer.question.type) {
        case "datetime":
          return moment(answer.answer).format("ddd, MMM DD, hh:mm A");
        case "time":
          return moment(answer.answer).format("hh:mm A");
        default:
          return answer.answer;
      }
    } else {
      return "No data";
    }
  } catch (error) {
    return "No data";
  }
}

const roles = {
  SENDER: "sender",
  RECEIVER: "receiver",
  ADMIN: "admin"
};

function getRole(channel, user) {
  for (let i = 0; i < channel.admins.length; i += 1) {
    if (channel.admins[i]._id === user._id) return roles.ADMIN;
  }
  for (let i = 0; i < channel.receivers.length; i += 1) {
    if (channel.receivers[i]._id === user._id) return roles.RECEIVER;
  }
  return roles.SENDER;
}

const months = ["JA", "FE", "MA", "AP", "MY", "JN", "JL", "AG", "SE", "OC", "NV", "DC"];

function getMonth(index = 0) {
  return months[index];
}

const getColorFormat = (color, alpha = 0.2) => {
  if (color === "transparent") {
    return "transparent";
  }
  const [r, g, b, a] = hexRgb(color, { alpha, format: "array" });
  return `rgba(${r},${g},${b},${a})`;
};

const getStatusProps = _status => {
  switch (_status) {
    case 1:
      return {
        label: "Over due or not resolved",
        style: { borderColor: "#0175BC", backgroundColor: "rgba(1,177,188,0.2)" },
        color: "#0175BC"
      };
    case 2:
      return {
        label: "Resolved",
        style: { borderColor: "#FFFF00", backgroundColor: "rgba(255,255,0,0.2)" },
        color: "#FFFF00"
      };
    case 3:
      return {
        label: "In progress (less than 50% ready)",
        style: { borderColor: "#00772C", backgroundColor: "rgba(1,255,95,0.2)" },
        color: "#00772C"
      };
    case 4:
      return {
        label: "In progress (more than 50% ready)",
        style: { borderColor: "#BC0101", backgroundColor: "rgba(188,1,1,0.2)" },
        color: "#BC0101"
      };
    default:
      return {
        label: "To do",
        style: { borderColor: "black", backgroundColor: "rgba(255,255,255,0.5)" },
        color: "black"
      };
  }
};

export { formatAnswer, roles, getRole, getMonth, getColorFormat, getStatusProps };
