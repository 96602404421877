const routes = {
  home: "/",
  signIn: "/sign-in",
  signUp: "/sign-up",
  forgotPassword: "/forgot-password",
  feedback: "/feedback",
  newChannel: "/new-channel",
  newWorkspace: "/new-workspace",
  summary: "/summary",
  post: "/post/:id",
  alert: "/alert/:id",
  calendar: "/calendar",
  members: "/members",
  addMember: "/add-member",
  member: "/member/:id",
  informationSent: "/information-sent",
  chat: "/chat",
  preConfiguredChannels: "/pre-configured-channels",
  editChannel: "/edit-channel",
  tableView: "/table-view",
  templates: "/templates",
  myAccount: "/my-account",
  invitation: "/invitation/:id",
};

export default routes;
