import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Archive, DateRange, InsertDriveFile, DeleteOutline, Add, DeleteForever } from "@material-ui/icons";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import moment from "moment";
import classNames from "classnames";
import { ImSortAmountAsc, ImSortAmountDesc } from "react-icons/im";
import styles from "./summary.module.css";
import Container from "../../components/container";
import routes from "../../routes";
import { StateContext } from "../../contexts/state";
import API from "../../api";
import Information from "../../components/information";
import { roles } from "../../utils";
import { StatusContext } from "../../contexts/status";
import Select from "../../components/select";
import Details from "../../components/details";
import translate from "../../translate";

export default function Summary(props) {
  const {
    location: { search },
  } = props;
  const { channel, user, role } = useContext(StateContext);
  const { updateErrorMessage, updateSuccessMessage } = useContext(StatusContext);
  const [informations, setInformations] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [selectedInformation, setSelectedInformation] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(true);
  const [showAllInfo, setShowAllInfo] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(localStorage.getItem("order") || "");
  const [selectedFilter, setSelectedFilter] = useState("Active");
  const [order, setOrder] = useState(localStorage.getItem("orderdir") || "1");
  const [filterIndex, setFilterIndex] = useState(null);
  const [hasData, setHasData] = useState(false);
  const [currentInformation, setCurrentInformation] = useState(null);

  // This variable can be used to define the order of filter (a-z) or (z-a)

  useEffect(() => {
    if (selectedOption.startsWith("question#") && order) {
      localStorage.setItem("order", selectedOption);
      localStorage.setItem("orderdir", order);
    } else {
      localStorage.removeItem("order");
      localStorage.removeItem("orderdir");
    }
  }, [selectedOption, order]);

  useEffect(() => {
    const clearCurrentInformation = (event) => {
      if (event.key === "Escape") {
        setCurrentInformation(null);
      }
    };
    window.addEventListener("keyup", clearCurrentInformation);
    return () => {
      window.removeEventListener("keyup", clearCurrentInformation);
    };
  }, []);

  const fetchData = async (limit = 0) => {
    try {
      setRefreshing(true);
      setIsSelectAll(false);
      const params = API.getParams({
        channel: channel._id,
        isArchived: false,
        order: selectedOption,
        orderDir: order,
        filter: selectedFilter,
        limit,
      }).replace("#", "%23");
      const { data: response } = await API.information().list(params);
      const searchParams = new URLSearchParams(search);
      if (searchParams.get("id")) {
        const _currentInformation = response.find((r) => r._id === searchParams.get("id"));
        if (_currentInformation) {
          setCurrentInformation(_currentInformation);
        }
      }
      setHasData(response.length > 0);
      setInformations(response);
      setRefreshing(false);
    } catch (error) {
      setRefreshing(false);
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  useEffect(() => {
    if (!channel._id) {
      return;
    }
    const _options = [
      { text: translate.summary.filters.last, value: "", dir: "1" },
      { text: translate.summary.filters.creation, value: "creation", dir: "-1" },
      {
        value: "sender",
        text: translate.summary.filters.sender,
        dir: "1",
      },
    ];
    channel.questions.forEach((question) => {
      if (question.type !== "picture") {
        _options.push({ text: question.text, value: `question#${question._id}`, dir: "1", position: question._id });
      }
    });
    setOptions(_options);
  }, [channel]);

  useEffect(() => {
    fetchData();
    setCurrentInformation(null);
  }, [channel, order, selectedFilter, selectedOption]);

  const onSelectAll = () => {
    const _isSelectAll = !isSelectAll;
    setIsSelectAll(_isSelectAll);
    if (_isSelectAll) {
      if (role === roles.ADMIN) {
        setSelectedInformation(informations.map((_information) => _information._id));
      } else {
        setSelectedInformation(
          informations.filter((_information) => _information.sender?._id === user._id).map((_information) => _information._id)
        );
      }
    } else {
      setSelectedInformation([]);
    }
  };

  const toggleSelectedInformation = (id) => {
    const _information = informations.find((i) => i._id === id);
    if (role !== roles.ADMIN && _information.sender?._id !== user._id) {
      return;
    }
    let _selectedInformation = [...selectedInformation];
    if (_selectedInformation.includes(id)) {
      _selectedInformation = _selectedInformation.filter((_id) => _id !== id);
    } else {
      _selectedInformation.push(id);
    }
    if (_selectedInformation.length === 0) {
      setIsSelectAll(false);
    }
    setSelectedInformation(_selectedInformation);
  };

  const onDeletePost = async () => {
    try {
      const isConfirm = window.confirm("Are you sure? This action can not be reversed");
      if (isConfirm) {
        await Promise.all(
          selectedInformation.map(async (id) => {
            try {
              await API.information(id).delete();
            } catch (error) {
              updateErrorMessage(API.getErrorMessage(error));
            }
          })
        );
        setSelectedInformation([]);
        await fetchData();
        updateSuccessMessage("Post deleted");
      }
    } catch (error) {
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const onArchivePost = async () => {
    try {
      await Promise.all(
        selectedInformation.map(async (id) => {
          try {
            await API.information(id).update({
              deletedBy: user._id,
              deletedAt: moment().toISOString(),
            });
          } catch (error) {
            updateErrorMessage(API.getErrorMessage(error));
          }
        })
      );
      setSelectedInformation([]);
      await fetchData();
      updateSuccessMessage("Post archived");
    } catch (error) {
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const onExportToExcel = async () => {
    try {
      const isConfirm = window.confirm("Receive all these posts in an Excel by email. Please confirm");
      if (isConfirm) {
        await API.file().report(informations.map((i) => i._id));
        updateSuccessMessage("Email sent");
      }
    } catch (error) {
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const onDelete = async () => {
    try {
      const isConfirm = window.confirm("Are you sure? This action can not be reversed");
      if (isConfirm) {
        await Promise.all(selectedInformation.map((_selectedInformation) => API.information(_selectedInformation).delete()));
        await fetchData();
      }
    } catch (error) {
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  return (
    <Container {...props} noScroll>
      <div className={styles.header}>
        <h3 className={styles.title}>{translate.summary.title}</h3>
      </div>
      <div className={classNames(styles.actionsContainer, styles.hidden)}>
        <input className={styles.checkbox} type="checkbox" value={isSelectAll} onChange={onSelectAll} disabled={!hasData} />
        {selectedFilter === "Archived" ? (
          <button
            className={styles.deleteArchiveIcon}
            disabled={selectedInformation.length === 0 || !hasData}
            onClick={onDeletePost}
          >
            <DeleteOutline />
          </button>
        ) : (
          <button disabled={selectedInformation.length === 0 || !hasData} onClick={onArchivePost}>
            <Archive />
          </button>
        )}
        <Link to={routes.calendar} className={styles.calendarIcon}>
          <DateRange />
        </Link>
        <button disabled={!hasData} className={styles.excelIcon} onClick={onExportToExcel}>
          <InsertDriveFile />
        </button>
        <button className={styles.viewMoreButton} onClick={() => setShowAllInfo(!showAllInfo)}>
          View {showAllInfo ? "Less" : "More"}
        </button>
      </div>
      <div className={styles.actionsContainer}>
        <div className={styles.actionsContainer}>
          <Select
            options={options.map(({ text: label, value }) => ({ label, value }))}
            value={selectedOption}
            onChange={({ value }) => {
              const option = options.find((o) => o.value === value);
              if (option && option.position) {
                setFilterIndex(option.position);
              } else {
                setFilterIndex(null);
              }
              setOrder(option.dir);
              setSelectedOption(value);
            }}
            alignItems="flex-start"
            fontSize={14}
          />
          <button className={styles.sortButton} onClick={() => setOrder(order === "1" ? "-1" : "1")}>
            {order === "1" ? <ImSortAmountDesc /> : <ImSortAmountAsc />}
          </button>
        </div>
        <Select
          options={[
            {
              label: translate.summary.sorts.active,
              value: "Active",
            },
            {
              label: translate.summary.sorts.unresolved,
              value: "Unresolved",
            },
            {
              label: translate.summary.sorts.resolved,
              value: "Resolved",
            },
            {
              label: translate.summary.sorts.upcoming,
              value: "Upcoming",
            },
            {
              label: translate.summary.sorts.pastDue,
              value: "Past due",
            },
            {
              label: translate.summary.sorts.archived,
              value: "Archived",
            },
          ]}
          value={selectedFilter}
          onChange={({ value }) => setSelectedFilter(value)}
          alignItems="flex-start"
          fontSize={14}
        />
      </div>
      {refreshing ? (
        <div className={styles.loaderContainer}>
          <Loader type="TailSpin" color="#012172" height={50} width={50} />
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.informationsContainer}>
            {informations.length ? (
              <>
                <div className={styles.checkboxContainer}>
                  <label className={styles.checkbox}>
                    <input type="checkbox" checked={isSelectAll} onChange={onSelectAll} />
                    Select all
                  </label>
                  {selectedInformation.length > 0 && (
                    <button onClick={onDelete} title={translate.details.delete}>
                      <DeleteForever />
                    </button>
                  )}
                </div>
                {informations.map((information) => (
                  <Information
                    key={information._id}
                    information={information}
                    showAllInfo={showAllInfo}
                    filterIndex={filterIndex}
                    selectedInformation={selectedInformation}
                    toggleSelectedInformation={toggleSelectedInformation}
                    onSelect={setCurrentInformation}
                    isSelect={isSelectAll}
                  />
                ))}
              </>
            ) : (
              <h4 className={styles.noInformation}>No information received</h4>
            )}
          </div>
          <div className={styles.divider}></div>
          <div className={styles.detailsContainer}>
            {currentInformation ? (
              <Details
                information={currentInformation}
                onDeleteConfirm={() => {
                  setCurrentInformation(null);
                  fetchData();
                }}
              />
            ) : (
              <div className={styles.noInformationSelected}>No row selected</div>
            )}
          </div>
        </div>
      )}
      <Link className={styles.enterInformationButton} to={routes.home}>
        <Add />
      </Link>
    </Container>
  );
}

Summary.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }),
};
