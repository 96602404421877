import React from "react";
import { IoThumbsUpSharp } from "react-icons/io5";
import { getStatusProps } from "../../utils";
import styles from "./styles.module.css";

function StatusPicker(props) {
  const { status } = props;

  return (
    <div className={styles.container} style={getStatusProps(status)?.style}>
      <IoThumbsUpSharp name="ios-thumbs-up-sharp" size={15} color={getStatusProps(status)?.color} />
    </div>
  );
}

export default React.memo(StatusPicker);
