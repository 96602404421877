import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import styles from "./newChannel.module.css";
import Container from "../../components/container";
import { StateContext } from "../../contexts/state";
import { StatusContext } from "../../contexts/status";
import API from "../../api";
import Question from "../../components/question";
import routes from "../../routes";
import translate from "../../translate";

export default function NewChannel(props) {
  const { history } = props;
  const { updateErrorMessage, updateSuccessMessage, updateWarningMessage } = useContext(StatusContext);
  const { workspace, user, rules, refreshChannels, updateChannel, updateStep } = useContext(StateContext);
  const [name, setName] = useState("");
  const [lifeSpan, setLifeSpan] = useState("");
  const [questions, setQuestions] = useState([{ id: shortid.generate(), text: "", type: "", isMandatory: false, dropdowns: [] }]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setQuestions([
      { id: shortid.generate(), text: "", type: "", isMandatory: false, dropdowns: [] },
      { id: shortid.generate(), text: "", type: "", isMandatory: false, dropdowns: [] },
    ]);
  }, [workspace]);

  const addNewQuestion = () => {
    const _questions = [...questions];
    if (!user.isPaid) {
      const maxQuestions = user.isPremium ? rules.premium.max_questions : rules.free.max_questions;
      if (_questions.length >= maxQuestions) {
        API.premiumEvents().new({ type: "max_questions" });
        updateWarningMessage("Upgrade your account. You have reached the maximum amount of fields for this channel");
        return;
      }
    }
    _questions.push({ id: shortid.generate(), text: "", type: "", isMandatory: false, dropdowns: [] });
    setQuestions(_questions);
  };

  const updateField = (id, text, type, isMandatory, dropdowns) => {
    let _questions = [...questions];
    _questions = _questions.map((_question) => {
      if (_question.id === id) {
        return { id, text, type, isMandatory, dropdowns };
      }
      return _question;
    });
    setQuestions(_questions);
  };

  const deleteField = (id) => {
    let _questions = [...questions];
    if (_questions.length === 1) {
      updateWarningMessage("You need to have at least one field");
      return;
    }
    _questions = _questions.filter((_question) => _question.id !== id);
    setQuestions(_questions);
  };

  const onCreateChannel = async () => {
    try {
      setIsLoading(true);
      const _questions = [...questions];
      const channel = await API.channel().new(name, workspace._id, _questions, lifeSpan);
      setName("");
      setQuestions([
        { id: shortid.generate(), text: "", type: "", isMandatory: false, dropdowns: [] },
        { id: shortid.generate(), text: "", type: "", isMandatory: false, dropdowns: [] },
      ]);
      await refreshChannels();
      updateChannel(channel.data);
      setIsLoading(false);
      updateSuccessMessage("Channel created, your channel has been created");
      if (user.lastLoggedIn) {
        history.push(routes.addMember);
      } else {
        updateStep(2);
        history.push(routes.home);
      }
    } catch (error) {
      setIsLoading(false);
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    onCreateChannel();
  };

  return (
    <Container {...props}>
      <div className={styles.header}>
        <h3 className={styles.title}>{translate.newChannel.title}</h3>
      </div>
      <form className={styles.form} onSubmit={onSubmit}>
        <label className={styles.label}>{translate.newChannel.name}</label>
        <input
          type="text"
          placeholder={translate.newChannel.name}
          className={styles.input}
          value={name}
          onChange={(e) => setName(e.target.value)}
          disabled={isLoading}
          required
        />
        {questions &&
          questions.map((question, index) => (
            <Question
              key={question.id}
              {...question}
              index={index}
              updateField={updateField}
              deleteField={deleteField}
              disabled={isLoading}
            />
          ))}
        {user.isPaid && (
          <>
            <label className={styles.label}>{translate.newChannel.lifeSpan}</label>
            <input
              type="text"
              placeholder={translate.newChannel.lifeSpan}
              className={styles.input}
              value={lifeSpan}
              onChange={(e) => setLifeSpan(e.target.value.replace(/[^0-9]/g, ""))}
              disabled={isLoading}
            />
          </>
        )}
        <div className={styles.actions}>
          <button type="button" className={styles.button} disabled={isLoading} onClick={addNewQuestion}>
            {translate.newChannel.add}
          </button>
          <button type="submit" className={styles.button} disabled={isLoading}>
            {translate.newChannel.submit}
          </button>
        </div>
      </form>
    </Container>
  );
}

NewChannel.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }),
};
