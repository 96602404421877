import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/database";
import "react-datepicker/dist/react-datepicker.css";

const firebaseConfig = {
  apiKey: "AIzaSyCrQLZW8JX9yKVMbdOLIVbdk7ZJgIxLdUQ",
  authDomain: "tabulate-it-7c982.firebaseapp.com",
  databaseURL: "https://tabulate-it-7c982-default-rtdb.firebaseio.com",
  projectId: "tabulate-it-7c982",
  storageBucket: "tabulate-it-7c982.appspot.com",
  messagingSenderId: "987316799378",
  appId: "1:987316799378:web:cd727762389a8708cea34b",
  measurementId: "G-S0D7RKVS4X"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
