const en = {
  home: {
    newTableTitle: 'You can edit or delete this table anytime, Go to "Edit this Table" in the side bar',
    ownTableDescription:
      "Configure each column of the table, so you or your team can easily enter the information of each row anytime",
    preconfiguredDescription:
      "Choose a table ready to use, like one you can use as your agenda, or to manage the tasks of your team and more",
    title: "Enter Record / Row",
    sent: "Record Created",
    lastRecordEntered: "Last record entered",
    reminderAt: "Reminder at",
    reminderTeam: "Reminder for the team at",
    reminderToMe: "Reminder to me until done",
    reminderResponsible: "Reminder to responsible at",
    none: "none",
    lifeSpan: "Lifespan of the records",
    submit: "Submit",
  },
  newChannel: {
    title: "Define name of the columns",
    name: "Name of the table",
    lifeSpan: "Lifespan of the records",
    sync: "Sync with google calendar",
    add: "Add more fields",
    submit: "Submit",
    editTable: "Edit table",
    delete: "Delete this table",
    edit: "Edit",
    reorganize: "Reorganize",
    fields: "fields",
  },
  templates: {
    title:
      'You can edit or delete these table anytime in "Edit this Table" in the side bar menu. Add or remove columns, change field types and more.',
    list: {
      name: "Create a List",
      description:
        "Create a to-do list or a grocery list. Keep it private or share with the family. Set reminders, add optional fields and more.",
    },
    calendar: {
      name: "Calendar/Agenda",
      description:
        "Easy-to-use calendar. Synchronize the data with Google Calendar. Set reminders, view information on the calendar or in a list, and much more.",
    },
    tasks: {
      name: "Team task manager",
      description:
        "Manage personal tasks or team projects. Add or remove participants, define priority, due dates, check reports and more.",
    },
    organize: {
      name: "Organize information communicated by people",
      description:
        "Easily organize information received from many people. Inventory management? Work orders? Suggestions to reduce cost or increase revenue.",
    },
    custom: {
      name: "Create your own table",
      description: "Configure each column of the table, so you or your team can easily enter the information of each row anytime",
    },
    select: "Select template",
    description: "Description",
    dueDate: "Due date",
    status: "Status",
    duration: "Duration",
    priority: "Priority",
    type: "Type",
    responsible: "Select responsible",
    todo: "Todo",
    inProgress: "In progress",
    testing: "Testing",
    resolved: "Resolved",
    notResolved: "Not resolved",
    urgent: "Urgent",
    high: "High",
    medium: "Medium",
    low: "Low",
    family: "Family",
    work: "Work",
    friends: "Friends",
    user1: "User 1",
    user2: "User 2",
    user3: "User 3",
    listName: "List",
    calendarName: "Calendar/Agenda",
    taskName: "Team task manager",
    organizeName: "Organize information",
  },
  sidebar: {
    customTable: "Create custom table",
    enterRecord: "Enter Record",
    createTable: "Create table",
    viewRows: "List view",
    tableView: "Table view",
    participants: "Participants in this table",
    editTable: "Edit this table",
    preconfiguredTables: "Preconfigured tables",
    sendMessages: "Send messages",
    myAccount: "My account",
    contactUs: "Contact us/Send feedback",
    help: "Help - FAQ",
    calendarView: "Calendar View",
  },
  members: {
    owner: "Owner",
    admins: "Admins",
    receivers: "Participants - Can View and Create Records",
    senders: "Participants 2 - Only can create the Records",
    pendingInvitations: "Pending Invitations",
    invite: "Invite participants to this table",
    noUsers: "No users",
    noInvitations: "No invitations",
  },
  addNewParticipant: {
    title: "Add new participant to table",
    privateMessage: "Private message",
    sender: "Sender: Only Can Send Post",
    receiver: "Receiver: Can Send & Review Posts",
    admin: "Admin: Can Send & Review & Edit",
    success: "Participant added. Set as",
    invitation: {
      title: "Invitation sent",
      body1: "Your invitation to",
      body2: "has been sent",
    },
  },
  summary: {
    title: "List of Records",
    filters: {
      last: "Last updated",
      creation: "Creation date",
      sender: "Sender",
      color: "Color",
    },
    sorts: {
      active: "Active",
      unresolved: "Unresolved",
      resolved: "Resolved",
      upcoming: "Upcoming",
      pastDue: "Past due",
      archived: "Archived",
    },
    view: "View",
    more: "more",
    less: "less",
  },
  addParticipants: {
    participants: "Participant - Can View and Create Records",
    skip: "Skip",
    participants2: "Participant 2 - Only can create the Records",
  },
  container: {
    export: "Export to excel",
    duplicate: "Duplicate this table",
    import: "Import table",
    premiumFeatures: "Premium features",
    tutorial: "Tutorial and FAQ",
  },
  feedback: {
    title: "Send your feedback",
    subtitle: "Thank you for your feedback. Your opinion is important for us",
    typeOfFeedback: "Type of Feedback",
    howImprove: "How can we improve our app?",
    priority: "Priority to have/resolve this feature?",
    submit: "Submit",
    problem: "problem/bug",
    feature: "add new feature",
    improvement: "improvement",
    comment: "comment",
    low: "low",
    medium: "medium",
    high: "high",
    selectType: "Select a type",
    writeMessage: "Write your message here",
    selectPriority: "Select a priority",
  },
  question: {
    column: "Column",
    enterName: "Enter column name",
    mandatory: "Mandatory",
    selectType: "Select a type",
    text: "Text",
    number: "Number",
    dateTime: "Date & Time",
    picture: "Picture",
    dropdown: "Dropdown",
    video: "Video",
    url: "URL",
    checkMark: "Check mark",
    time: "Only time",
    date: "Only date",
  },
  table: {
    search: "Search",
    comments: "Comments",
    alert: "Do you want to go to the Record View?",
    cancel: "Cancel",
    yes: "Yes",
    export: "Export",
    add: "Add rows",
  },
  signIn: {
    title: "Sign In",
    message: "Hi there! Nice to see you again.",
    email: "Email",
    password: "Password",
    submit: "Sign In",
    forgotPassword: "Forgot password?",
    register: "Register",
  },
  details: {
    delete: "Delete",
    archive: "Archive",
    unarchive: "Unarchive",
  },
  invitation: {
    title: "You are being invited to the table:",
    email: "Email",
    submit: "Validate",
    signIn: "Accept invitation",
  },
  mainTab: {
    letsCreate: "Let's create our first table",
    pleaseClick: "Please click",
    createCustomTable: "Create custom table",
    next: "Next",
    letsDefine: 'Lets define the columns of our "grocery-list" table',
    letsPut: "Lets put in your table, the list of things you want to buy",
    thisIsTheTableView:
      'This is the "Table View" of the table you just created. You can also view this information as a list by clicking "List View" in the sidebar.',
    close: "Close",
  },
  reminders: {
    daily: "Daily",
    everyotherday: "Every other day",
    weekly: "Weekly",
    monthly: "Monthly",
    onceayear: "Once a year",
  },
  signUp: {
    confirm:
      "To finish your registration, please check your email in a few minutes. We sent you a verification email. Check the Spam folder too.",
  },
  editChannel: {
    confirm: "Channel edited, your channel has been edited",
  },
  addMember: {
    skip: "Skip",
  },
};

export default en;
