import axios from "axios";
import { state } from "../constants";

const { TOKEN } = state;

let baseURL = "https://api.tabularplan.com/api";
if (process.env.NODE_ENV === "development") baseURL = "http://localhost:5001/api";
axios.defaults.baseURL = baseURL;
axios.defaults.headers.timezoneOffset = new Date().getTimezoneOffset();

function getToken() {
  const token = localStorage.getItem(TOKEN);
  return token;
}

const GET = "GET";
const POST = "POST";
const PATCH = "PATCH";
const DELETE = "DELETE";

const API = {
  user: (id) => {
    let url = `${baseURL}/user`;
    return {
      list: async (params) => {
        url += `/?${params || ""}`;
        const r = await axios({
          url,
          method: GET,
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      signin: async (email, password, pushToken) => {
        url += "/signin";
        const r = await axios({
          url,
          method: POST,
          data: { email, password, pushToken },
        });
        return r.data;
      },
      signup: async (firstName, lastName, email, password, language) => {
        url += "/signup";
        const r = await axios({
          url,
          method: POST,
          data: { firstName, lastName, email, password, language },
        });
        return r.data;
      },
      identify: async () => {
        url += "/identify";
        const r = await axios({
          url,
          method: GET,
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      notifications: async (isPostsNotifications, isAlertsNotifications) => {
        url += "/notifications";
        const r = await axios({
          url,
          method: PATCH,
          data: { isPostsNotifications, isAlertsNotifications },
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      signout: async (pushToken) => {
        url += "/signout";
        const r = await axios({
          url,
          method: POST,
          data: { pushToken },
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      password: () => {
        url += "/password";
        return {
          reset: async (params) => {
            url += `/reset?${params}`;
            const r = await axios({
              url,
              method: GET,
              headers: { "X-USER-TOKEN": getToken() },
            });
            return r.data;
          },
          new: async (password, newPassword) => {
            const _url = `${baseURL}/user/${id}/password/new`;
            const r = await axios({
              url: _url,
              method: PATCH,
              data: { password, newPassword },
              headers: { "X-USER-TOKEN": getToken() },
            });
            return r.data;
          },
        };
      },
      email: async (email) => {
        url += "/email";
        const r = await axios({
          url,
          method: PATCH,
          data: { email },
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      cards: async () => {
        url += "/cards";
        const r = await axios({
          url,
          method: GET,
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      addCard: async (data) => {
        url += "/addCard";
        const r = await axios({
          url,
          method: POST,
          data: {
            data,
          },
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      defaultPaymentMethod: async (_id) => {
        url += "/defaultPaymentMethod";
        const r = await axios({
          url,
          method: PATCH,
          data: {
            id: _id,
          },
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      updateCoupon: async (subscriptionCoupon) => {
        url += "/coupon";
        const r = await axios({
          url,
          method: PATCH,
          data: {
            subscriptionCoupon,
          },
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      updateLanguage: async (language) => {
        url += "/language";
        const r = await axios({
          url,
          method: PATCH,
          data: {
            language,
          },
          headers: { "X-USER-TOKEN": await getToken() },
        });
        return r.data;
      },
      validate: async (email) => {
        url += "/validate";
        const r = await axios({
          url,
          method: POST,
          data: { email },
        });
        return r.data;
      },
    };
  },
  workspace: (id) => {
    let url = `${baseURL}/workspace`;
    return {
      list: async () => {
        const r = await axios({
          url,
          method: GET,
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      get: async () => {
        url += `/${id}`;
        const r = await axios({
          url,
          method: GET,
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      new: async (name) => {
        url += "/new";
        const r = await axios({
          url,
          method: POST,
          data: { name },
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      rename: async (name) => {
        url += `/${id}`;
        const r = await axios({
          url,
          method: PATCH,
          data: { name },
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      delete: async () => {
        url += `/${id}`;
        const r = await axios({
          url,
          method: DELETE,
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
    };
  },
  channel: (id) => {
    let url = `${baseURL}/channel`;
    return {
      list: async (params) => {
        url += `?${params || ""}`;
        const r = await axios({
          url,
          method: GET,
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      get: async () => {
        url += `/${id}`;
        const r = await axios({
          url,
          method: GET,
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      new: async (name, workspace, questions, lifeSpan) => {
        url += "/new";
        const r = await axios({
          url,
          method: POST,
          data: { name, workspace, questions, lifeSpan },
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      update: async (name, questions, lifeSpan) => {
        url += `/${id}`;
        const r = await axios({
          url,
          method: PATCH,
          data: { name, questions, lifeSpan },
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      addAdmin: async (email, message) => {
        url += `/${id}/admin`;
        const r = await axios({
          url,
          method: PATCH,
          data: { email, message },
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      removeAdmin: async (userId) => {
        url += `/${id}/admin`;
        const r = await axios({
          url,
          method: DELETE,
          data: { id: userId },
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      addReceiver: async (email, message) => {
        url += `/${id}/receiver`;
        const r = await axios({
          url,
          method: PATCH,
          data: { email, message },
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      removeReceiver: async (userId) => {
        url += `/${id}/receiver`;
        const r = await axios({
          url,
          method: DELETE,
          data: { id: userId },
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      addSender: async (email, message) => {
        url += `/${id}/sender`;
        const r = await axios({
          url,
          method: PATCH,
          data: { email, message },
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      removeSender: async (userId) => {
        url += `/${id}/sender`;
        const r = await axios({
          url,
          method: DELETE,
          data: { id: userId },
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      delete: async () => {
        url += `/${id}`;
        const r = await axios({
          url,
          method: DELETE,
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      preConfigured: async (name, questions) => {
        url += "/pre-configured";
        const r = await axios({
          url,
          method: POST,
          data: { name, questions },
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      copy: async (name, questions, lifeSpan) => {
        url += "/copy";
        const r = await axios({
          url,
          method: POST,
          data: { name, questions, lifeSpan },
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      table: async () => {
        url += `/${id}/table`;
        const r = await axios({
          url,
          method: GET,
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
    };
  },
  information: (id) => {
    let url = `${baseURL}/information`;
    return {
      list: async (params) => {
        url += `?${params || ""}`;
        const r = await axios({
          url,
          method: GET,
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      get: async () => {
        url += `/${id}`;
        const r = await axios({
          url,
          method: GET,
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      calendar: async (query) => {
        url += "/calendar";
        const r = await axios({
          url,
          method: POST,
          data: { query },
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      new: async (data) => {
        const r = await axios({
          url,
          method: POST,
          data,
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      update: async (data) => {
        url += `/${id}`;
        const r = await axios({
          url,
          method: PATCH,
          data,
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      delete: async () => {
        url += `/${id}`;
        const r = await axios({
          url,
          method: DELETE,
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
    };
  },
  feedback: () => {
    const url = `${baseURL}/feedback`;
    return {
      new: async (type, message, priority) => {
        const r = await axios({
          url,
          method: POST,
          data: { type, message, priority },
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
    };
  },
  alert: (id) => {
    let url = `${baseURL}/alert`;
    return {
      list: async (params) => {
        url += `?${params || ""}`;
        const r = await axios({
          url,
          method: GET,
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      new: async (data) => {
        const r = await axios({
          url,
          method: POST,
          data,
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      update: async (date, period = "daily") => {
        url += `/${id}`;
        const r = await axios({
          url,
          method: PATCH,
          data: { date, period },
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
    };
  },
  file: (type = "") => {
    let url = `${baseURL}/file`;
    return {
      upload: async (file) => {
        const formData = new FormData();
        formData.append("file", file);
        const r = await axios({
          url,
          method: POST,
          data: formData,
          headers: { "X-USER-TOKEN": getToken(), "Content-Type": "multipart/form-data" },
        });
        return r.data;
      },
      report: async (ids = []) => {
        url += "/report";
        const data = {
          $or: ids.map((id) => ({
            _id: id,
          })),
        };
        const r = await axios({
          url,
          method: POST,
          data,
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      reportChannel: async (channel) => {
        url += `/report/${type}`;
        const data = { channel };
        const r = await axios({
          url,
          method: POST,
          data,
          headers: { "X-USER-TOKEN": await getToken() },
        });
        return r.data;
      },
    };
  },
  message: () => {
    const url = `${baseURL}/message`;
    return {
      nofity: async (channel) => {
        const r = await axios({
          url,
          method: POST,
          data: { channel },
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
    };
  },
  premiumEvents: () => {
    const url = `${baseURL}/premiumEvents`;
    return {
      new: async (data) => {
        const r = await axios({
          url,
          method: POST,
          data,
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
    };
  },
  rule: () => {
    const url = `${baseURL}/rule`;
    return {
      list: async () => {
        const r = await axios({
          url,
          method: GET,
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
    };
  },
  invitation: () => {
    let url = `${baseURL}/invitation`;
    return {
      list: async (params) => {
        url += `/?${params || ""}`;
        const r = await axios({
          url,
          method: GET,
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      delete: async (id) => {
        url += `/${id}`;
        const r = await axios({
          url,
          method: DELETE,
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
      resend: async (id) => {
        url += `/resend/${id}`;
        const r = await axios({
          url,
          method: POST,
          headers: { "X-USER-TOKEN": getToken() },
        });
        return r.data;
      },
    };
  },
  getParams: (data) => {
    const params = Object.keys(data)
      .map((k) => `${k}=${data[k]}`)
      .join("&");
    return params;
  },
  getErrorMessage: (e = new Error("Unknown error")) => {
    return e.response?.data.message || e.message;
  },
  baseURL,
};

export default API;
