import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { AddBoxOutlined, ExitToApp } from "@material-ui/icons";
import classNames from "classnames";
import { MdTableRows } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";
import { FaListUl, FaTable } from "react-icons/fa";
import { IoPeople, IoPerson } from "react-icons/io5";
import { MdTableChart, MdOutlineMessage, MdOutlineEmail } from "react-icons/md";
import { FiHelpCircle } from "react-icons/fi";
import firebase from "firebase/app";
import styles from "./sidebar.module.css";
import routes from "../../routes";
import { StateContext } from "../../contexts/state";
import Select from "../select";
import translate, { changeLanguage } from "../../translate";
import spain from "../../assets/icons/spain.png";
import unitedKingdom from "../../assets/icons/united-kingdom.png";
import API from "../../api";

function CustomLink(props) {
  const { route, label, location, Icon, secondaryRoute, onClick, isAsterisk } = props;

  return (
    <div
      className={classNames(styles.linkContainer, {
        [styles.linkContainerActive]: location.pathname === route,
      })}
    >
      <Link to={route} className={styles.linkButton} onClick={onClick}>
        <div className={styles.linkButtonIcon}>
          <Icon />
        </div>
        <span>{label}</span>
        {isAsterisk && <span className={styles.asterisk}>*</span>}
      </Link>
      {secondaryRoute && (
        <Link to={secondaryRoute} className={styles.linkButtonSecondaryRoute}>
          <AddBoxOutlined />
        </Link>
      )}
    </div>
  );
}

export default function Sidebar(props) {
  const { channels, channel, updateChannel, updateIsLoggedIn, updateStep, user } = useContext(StateContext);
  const [isNewInformation, setIsNewInformation] = useState(false);

  useEffect(() => {
    firebase
      .database()
      .ref("events")
      .child("channels")
      .child(channel._id || "TABULATE")
      .child(user._id)
      .on("value", (data) => {
        const value = data.val();
        if (value) {
          setIsNewInformation(false);
        } else {
          setIsNewInformation(true);
        }
      });
    return () => {
      return firebase
        .database()
        .ref("events")
        .child("channels")
        .child(channel._id || "TABULATE")
        .child(user._id)
        .off("value");
    };
  }, [channel]);

  const isChannel = Boolean(channel?._id);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Link to={routes.home}>
          <h1>Tabular Plan</h1>
        </Link>
      </div>
      <div className={styles.select}>
        <Select
          options={channels.map(({ name: label, _id: value }) => ({
            value,
            label,
          }))}
          value={channel._id}
          onChange={(option) => updateChannel(channels.find((_channel) => _channel._id === option.value))}
          color="white"
          border="none"
          bold
          fontSize={22}
        />
      </div>
      <div className={styles.divider} />
      <div>
        <CustomLink
          {...props}
          label={translate.sidebar.customTable}
          route={routes.newChannel}
          Icon={MdTableRows}
          onClick={() => updateStep(1)}
        />
        {isChannel && <CustomLink {...props} label={translate.sidebar.enterRecord} route={routes.home} Icon={AiOutlineEdit} />}
        {isChannel && (
          <CustomLink
            {...props}
            label={translate.sidebar.viewRows}
            route={routes.summary}
            Icon={FaListUl}
            isAsterisk={isNewInformation}
            onClick={() =>
              firebase
                .database()
                .ref("events")
                .child("channels")
                .child(channel._id || "TABULATE")
                .child(user._id)
                .set(true)
            }
          />
        )}
        {isChannel && <CustomLink {...props} label={translate.sidebar.tableView} route={routes.tableView} Icon={FaTable} />}
        {isChannel && <CustomLink {...props} label={translate.sidebar.participants} route={routes.members} Icon={IoPeople} />}
        {isChannel && <CustomLink {...props} label={translate.sidebar.editTable} route={routes.editChannel} Icon={FaTable} />}
        <CustomLink {...props} label={translate.sidebar.preconfiguredTables} route={routes.templates} Icon={MdTableChart} />
        {isChannel && (
          <CustomLink {...props} label={translate.sidebar.sendMessages} route={routes.chat} Icon={MdOutlineMessage} />
        )}
        <CustomLink {...props} label={translate.sidebar.myAccount} route={routes.myAccount} Icon={IoPerson} />
        <CustomLink {...props} label={translate.sidebar.contactUs} route={routes.feedback} Icon={MdOutlineEmail} />
        <CustomLink {...props} label={translate.sidebar.help} route="#" Icon={FiHelpCircle} />
        <div className={classNames(styles.linkContainer)}>
          <button className={styles.linkButton} onClick={() => updateIsLoggedIn(false)}>
            <div className={styles.linkButtonIcon}>
              <ExitToApp fontSize="small" />
            </div>
            <span>Logout</span>
          </button>
        </div>
        <div className={styles.buttonsContainer}>
          <button
            onClick={() => {
              API.user().updateLanguage("en");
              changeLanguage("en");
            }}
          >
            <img src={unitedKingdom} alt="unitedKingdom" />
          </button>
          <button
            onClick={() => {
              API.user().updateLanguage("es");
              changeLanguage("es");
            }}
          >
            <img src={spain} alt="spain" />
          </button>
        </div>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }),
};
