const es = {
  home: {
    newTableTitle: 'Puede editar o eliminar esta tabla en cualquier momento, Vaya a "Editar esta tabla" en el menu lateral',
    ownTableDescription:
      "Configure cada columna de la tabla, para que usted o su equipo puedan ingresar fácilmente la información de cada fila en cualquier momento",
    preconfiguredDescription:
      "Elige una tabla lista para usar, como una que puedas usar como agenda, o para gestionar las tareas de tu equipo y más",
    title: "Ingresa Registro / Fila",
    sent: "Registro Creado",
    lastRecordEntered: "Último registro ingresado",
    reminderAt: "Recordarne el día",
    reminderTeam: "Recordar al equipo",
    reminderToMe: "Recordarme cada cierto tiempo",
    reminderResponsible: "Recordar al responsable",
    at: "a las",
    none: "nunca",
    lifeSpan: "Tiempo de vida de los registros",
    submit: "Crear",
  },
  newChannel: {
    title: "Define el nombre de las columnas",
    name: "Nombre de la tabla",
    lifeSpan: "Tiempo de vida de los registros",
    sync: "Sincronizar con google calendar",
    add: "Agregar más columnas",
    submit: "Crear",
    editTable: "Editar tabla",
    delete: "Eliminar esta tabla",
    edit: "Editar",
    reorganize: "Reorganizar",
    fields: "campos",
  },
  templates: {
    title:
      'Puedes editar o borrar estas tablas en cualquier momento en "Editar esta tabla" en el menú lateral. Agrega o elimina columnas, cambia los tipos de variables y más.',
    list: {
      name: "Crea una lista",
      description:
        "Crea una lista de tareas pendientes o una lista de la compra. Manténgalo privado o compártalo con la familia. Configure recordatorios, agregue campos opcionales y más.",
    },
    calendar: {
      name: "Calendario/Agenda",
      description:
        "Calendario fácil de usar. Sincroniza los datos con Google Calendar. Establezca recordatorios, vea información en el calendario o en una lista, y mucho más.",
    },
    tasks: {
      name: "Administrador de tareas del equipo",
      description:
        "Gestionar tareas personales o proyectos de equipo. Agregue o elimine participantes, defina prioridad, fechas de vencimiento, verifique informes y más.",
    },
    organize: {
      name: "Organizar la información comunicada por las personas.",
      description:
        "Organice fácilmente la información recibida de muchas personas. ¿La gestión del inventario? ¿Órdenes de trabajo? Sugerencias para reducir costos o aumentar los ingresos.",
    },
    custom: {
      name: "Crea tu propia tabla",
      description:
        "Configure cada columna de la tabla, para que usted o su equipo puedan ingresar fácilmente la información de cada fila en cualquier momento",
    },
    select: "Seleccionar plantilla",
    description: "Descripción",
    dueDate: "Fecha de vencimiento",
    status: "Estado",
    duration: "Duración",
    priority: "Prioridad",
    type: "Tipo",
    responsible: "Seleccionar responsable",
    todo: "Por hacer",
    inProgress: "En progreso",
    testing: "Pruebas",
    resolved: "Resuelto",
    notResolved: "No resuelto",
    urgent: "Urgente",
    high: "ALto",
    medium: "Medio",
    low: "Bajo",
    family: "Familia",
    work: "Trabajo",
    friends: "Amigos",
    user1: "Usuario 1",
    user2: "Usuario 2",
    user3: "Usuario 3",
    listName: "Lista",
    calendarName: "Calendario/Agenda",
    taskName: "Administrador de tareas del equipo",
    organizeName: "Organizar información",
  },
  sidebar: {
    customTable: "Crear tabla personalizada",
    enterRecord: "Ingresar registro",
    createTable: "Crear tabla",
    viewRows: "Vista de lista",
    tableView: "Vista tabla",
    participants: "Participantes en esta tabla",
    editTable: "Editar esta tabla",
    preconfiguredTables: "Tablas preconfiguradas",
    sendMessages: "Enviar mensajes",
    myAccount: "Mi cuenta",
    contactUs: "Contactenos/Envianos feedback",
    help: "Ayuda - Preguntas frecuentes",
    calendarView: "Vista calendario",
  },
  members: {
    owner: "Dueño",
    admins: "Admin",
    receivers: "Participantes - Pueden Ver y Crear Registros",
    senders: "Participantes 2, Solo pueden crear registros",
    pendingInvitations: "Invitaciones Pendientes",
    invite: "Invitar participantes a esta tabla",
    noUsers: "Sin usuarios",
    noInvitations: "Sin invitaciones",
  },
  addNewParticipant: {
    title: "Añadir nuevo participante a la tabla",
    privateMessage: "Mensaje privado",
    sender: "Remitente: Solo puede enviar información",
    receiver: "Receptor: Puede enviar y revisar información",
    admin: "Admin: Puede enviar y revisar y editar",
    success: "Participante agregado. Establecido como",
    invitation: {
      title: "Invitación enviada",
      body1: "La invitación a",
      body2: "ha sido enviada",
    },
  },
  summary: {
    title: "Lista de registros",
    filters: {
      last: "Último actualizado",
      creation: "Fecha de creación",
      sender: "Remitente",
      color: "Color",
    },
    sorts: {
      active: "Activo",
      unresolved: "Sin resolver",
      resolved: "Resueltos",
      upcoming: "Próximas",
      pastDue: "Vencido",
      archived: "Archivada",
    },
    view: "Ver",
    more: "más",
    less: "menos",
  },
  addParticipants: {
    participants: "Participante - Pueden Ver y Crear Registros",
    skip: "Saltar",
    participants2: "Participante 2, Solo pueden crear registros",
  },
  container: {
    export: "Exportar esta tabla",
    duplicate: "Duplicar esta tabla",
    import: "Importar nueva tabla",
    premiumFeatures: "Funcionalidades premium",
    tutorial: "Preguntas frecuentes",
  },
  feedback: {
    title: "Envía tus comentarios",
    subtitle: "Gracias por contactarnos. Su opinión es importante para nosotros",
    typeOfFeedback: "Tipo de comentario",
    howImprove: "¿Cómo podemos mejorar nuestra aplicación?",
    priority: "¿Prioridad para tener/resolver esta función?",
    submit: "Crear",
    problem: "problema detectado",
    feature: "crear nueva funcionalidad",
    improvement: "mejora",
    comment: "comentario",
    low: "baja",
    medium: "media",
    high: "alta",
    selectType: "Selecciona un tipo",
    writeMessage: "Escribe tu mensaje aquí",
    selectPriority: "Selecciona una prioridad",
  },
  question: {
    column: "Columna",
    enterName: "Nombre de la columna",
    mandatory: "Requerido",
    selectType: "Selecciona un tipo",
    text: "Texto",
    number: "Número",
    dateTime: "Fecha y hora",
    picture: "Imagen",
    dropdown: "Seleccionar opciones",
    video: "Video",
    url: "URL",
    checkMark: "Marca de verificación en la tabla",
    time: "Solo hora",
    date: "Solo fecha",
  },
  table: {
    search: "Buscar",
    comments: "Comentarios",
    alert: "¿Quiere ir a la vista de registro?",
    cancel: "Cancelar",
    yes: "Si",
    export: "Exportar",
    add: "Crear filas",
  },
  signIn: {
    title: "Iniciar sesión",
    message: "¡Hola! Encantado de verte de nuevo.",
    email: "Correo electrónico",
    password: "Contraseña",
    submit: "Iniciar sesión",
    forgotPassword: "¿Olvidó su contraseña?",
    register: "Registrarse",
  },
  details: {
    delete: "Borrar",
    archive: "Archivar",
    unarchive: "Desarchivar",
  },
  invitation: {
    title: "Estás siendo invitado a la tabla:",
    email: "Correo electrónico",
    submit: "Validar",
    signIn: "Aceptar invitación",
  },
  mainTab: {
    letsCreate: "Vamos a crear nuestra primera tabla",
    pleaseClick: "Por favor seleccione",
    createCustomTable: "Crear tabla personalizada",
    next: "Siguiente",
    letsDefine: 'Vamos a definir las columnas para nuestra tabla con la "lista de compras"',
    letsPut: "Pongamos en tu tabla, la lista de cosas que quieres comprar",
    thisIsTheTableView:
      'Esta es la "Vista de tabla", con la tabla que acabas de crear. También puedes ver esta información como una lista, haciendo click en "Vista de lista" del menú lateral.',
    close: "Cerrar",
  },
  reminders: {
    daily: "Diario",
    everyotherday: "Día de por medio",
    weekly: "Semanal",
    monthly: "Mensual",
    onceayear: "Una vez al año",
  },
  signUp: {
    confirm:
      "Para finalizar su registro, por favor revise su correo electrónico en unos minutos. Te enviamos un correo electrónico de verificación. Compruebe también la carpeta de correo no deseado.",
  },
  editChannel: {
    confirm: "Canal editado, tu canal ha sido editado",
  },
  addMember: {
    skip: "Saltar",
  },
};

export default es;
