import React, { useContext, useEffect, useState } from "react";
import styles from "./styles.module.css";
import Container from "../../components/container";
import { StateContext } from "../../contexts/state";
import API from "../../api";
import { StatusContext } from "../../contexts/status";

function MyAccount(props) {
  const { user, updateUser } = useContext(StateContext);
  const { updateErrorMessage, updateSuccessMessage } = useContext(StatusContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isPostsNotifications, setIsPostsNotifications] = useState(false);
  const [isAlertsNotifications, setIsAlertsNotifications] = useState(false);

  useEffect(() => {
    setEmail(user.email);
    setIsPostsNotifications(user.isPostsNotifications);
    setIsAlertsNotifications(user.isAlertsNotifications);
  }, [user]);

  const onEmailChange = async () => {
    try {
      await API.user().email(email);
      window.location.reload();
    } catch (error) {
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const onPasswordChange = async () => {
    try {
      if (newPassword !== confirmNewPassword) throw new Error("New Passwords do not match");
      await API.user(user._id).password().new(password, newPassword);
      setPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
      updateSuccessMessage("Password changed");
    } catch (error) {
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const onSaveSettings = async () => {
    try {
      await API.user().notifications(isPostsNotifications, isAlertsNotifications);
      updateUser({ ...user, isPostsNotifications, isAlertsNotifications });
      updateSuccessMessage("Saved");
    } catch (error) {
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  return (
    <Container {...props}>
      <h3 className={styles.title}>Settings</h3>
      <div className={styles.wrapper}>
        <form
          onSubmit={e => {
            e.preventDefault();
            onEmailChange();
          }}
        >
          <label>
            Email
            <input
              type="email"
              placeholder="Email"
              className={styles.input}
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
          </label>
          <button type="submit" className={styles.button} disabled={email === user.email}>
            Update email
          </button>
        </form>
        <br />
        <form
          onSubmit={e => {
            e.preventDefault();
            onPasswordChange();
          }}
        >
          <label>
            Password
            <input
              type="password"
              placeholder="Password"
              className={styles.input}
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
            />
          </label>
          <label>
            New Password
            <input
              type="password"
              placeholder="Password"
              className={styles.input}
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
              required
            />
          </label>
          <label>
            Confirm New Password
            <input
              type="password"
              placeholder="Password"
              className={styles.input}
              value={confirmNewPassword}
              onChange={e => setConfirmNewPassword(e.target.value)}
              required
            />
          </label>
          <button type="submit" className={styles.button}>
            Change password
          </button>
        </form>
        <br />
        <form
          onSubmit={e => {
            e.preventDefault();
            onSaveSettings();
          }}
        >
          <label className={styles.checkbox}>
            <input type="checkbox" checked={isPostsNotifications} onChange={e => setIsPostsNotifications(e.target.checked)} />
            Enable posts notifications
          </label>
          <label className={styles.checkbox}>
            <input type="checkbox" checked={isAlertsNotifications} onChange={e => setIsAlertsNotifications(e.target.checked)} />
            Enable alerts notifications
          </label>
          <button type="submit" className={styles.button}>
            Save
          </button>
        </form>
      </div>
    </Container>
  );
}

export default MyAccount;
