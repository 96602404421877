import React from "react";
import PropTypes from "prop-types";
import { Person, DeleteOutline, Edit, Replay } from "@material-ui/icons";
import { Link } from "react-router-dom";
import styles from "./member.module.css";
import { useContext } from "react";
import { StateContext } from "../../contexts/state";
import { roles } from "../../utils";
import routes from "../../routes";

export default function Member(props) {
  const { member, onDeleteUser, isInvitation, onResendInvitation, onRemoveInvitation, isOwner } = props;
  const { user, role } = useContext(StateContext);

  const isEdit = member._id !== user._id && role === roles.ADMIN;
  const isDelete = (member._id === user._id || role === roles.ADMIN) && !isOwner;

  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <Person />
      </div>
      <span className={styles.userName}>{isInvitation ? member.email : `${member.firstName} ${member.lastName}`}</span>
      {isInvitation ? (
        <button className={styles.action} onClick={() => onResendInvitation(member._id, member.email)}>
          <Replay />
        </button>
      ) : (
        isEdit && (
          <Link className={styles.action} to={routes.member.replace(":id", member._id)}>
            <Edit />
          </Link>
        )
      )}
      {(isDelete || isInvitation) && (
        <button
          className={styles.action}
          onClick={() =>
            isInvitation
              ? onRemoveInvitation(member._id, member.email)
              : onDeleteUser(member._id, `${member.firstName} ${member.lastName}`)
          }
        >
          <DeleteOutline />
        </button>
      )}
    </div>
  );
}

Member.propTypes = {
  member: PropTypes.object.isRequired,
  onDeleteUser: PropTypes.func,
  isInvitation: PropTypes.bool,
  onResendInvitation: PropTypes.func,
  onRemoveInvitation: PropTypes.func,
  isOwner: PropTypes.bool
};

Member.defaultProps = {
  isInvitation: false,
  isOwner: false
};
