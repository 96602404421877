import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Delete, Add } from "@material-ui/icons";
import styles from "./question.module.css";
import { StateContext } from "../../contexts/state";
import API from "../../api";
import { StatusContext } from "../../contexts/status";
import translate from "../../translate";

export default function Question(props) {
  const { index, id, text, type, isMandatory, dropdowns, updateField, deleteField, disabled, isReorganizing } = props;
  const { user, rules } = useContext(StateContext);
  const { updateWarningMessage } = useContext(StatusContext);

  const options = [
    {
      text: translate.question.text,
      value: "text",
    },
    {
      text: translate.question.number,
      value: "number",
    },
    {
      text: translate.question.dateTime,
      value: "datetime",
    },
    {
      text: translate.question.picture,
      value: "picture",
    },
    {
      text: translate.question.dropdown,
      value: "dropdown",
    },
    {
      text: translate.question.video,
      value: "video",
    },
    {
      text: translate.question.url,
      value: "url",
    },
    {
      text: translate.question.checkMark,
      value: "checkmark",
    },
    {
      text: translate.question.time,
      value: "time",
    },
    {
      text: translate.question.date,
      value: "date",
    },
  ];

  const onAddDropdown = () => {
    if (!user.isPaid) {
      const maxDropDowns = user.isPremium ? rules.premium.max_dropdown_options : rules.free.max_dropdown_options;
      if (dropdowns.length >= maxDropDowns) {
        API.premiumEvents().new({ type: "max_options_dropdown" });
        updateWarningMessage("Upgrade your account. You have reached the maximum amount of options for this dropdown");
        return;
      }
    }
    const _dropdowns = [...dropdowns];
    _dropdowns.push("");
    updateField(id, text, type, isMandatory, _dropdowns);
  };

  const onRemoveDropdown = (i) => {
    const _dropdowns = [...dropdowns];
    _dropdowns.splice(i, 1);
    updateField(id, text, type, isMandatory, _dropdowns);
  };

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <label className={styles.label}>
          <b>{`${translate.question.column} #${index + 1}`}</b>
        </label>
        <button type="button" className={styles.deleteButton} onClick={() => deleteField(id)}>
          <Delete fontSize="small" />
        </button>
      </div>
      <input
        type="text"
        placeholder={translate.question.enterName}
        className={styles.input}
        value={text}
        onChange={(e) => updateField(id, e.target.value, type, isMandatory, dropdowns)}
        disabled={disabled}
        required
      />
      <div className={styles.wrapper}>
        <select
          className={styles.select}
          required
          value={type}
          onChange={(e) => {
            const _dropdowns = [];
            if (e.target.value === "dropdown" && !dropdowns.length) _dropdowns.push("");
            updateField(id, text, e.target.value, isMandatory, _dropdowns);
          }}
          disabled={disabled}
        >
          <option value="" disabled>
            {translate.question.selectType}
          </option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>
        <label className={styles.mandatoryLabel}>
          <input
            type="checkbox"
            className={styles.mandatoryInput}
            checked={isMandatory}
            onChange={(e) => updateField(id, text, type, e.target.checked, dropdowns)}
            disabled={disabled}
          />
          {translate.question.mandatory}
        </label>
      </div>
      {type === "dropdown" && (
        <div style={{ marginTop: "10px" }}>
          {dropdowns.map((dropdown, i) => (
            <div key={String(i)} className={styles.dropdown}>
              <input
                type="text"
                placeholder={`Option #${i + 1}`}
                className={styles.input}
                value={dropdown}
                onChange={(e) => {
                  const _dropdowns = [...dropdowns];
                  _dropdowns[i] = e.target.value;
                  updateField(id, text, type, isMandatory, _dropdowns);
                }}
                disabled={disabled}
                required
              />
              {i === dropdowns.length - 1 ? (
                <button type="button" className={styles.dropdownAddButton} onClick={onAddDropdown} disabled={disabled}>
                  <Add fontSize="small" />
                </button>
              ) : (
                <button
                  type="button"
                  className={styles.dropdownDeleteButton}
                  onClick={() => onRemoveDropdown(i)}
                  disabled={disabled}
                >
                  <Delete fontSize="small" />
                </button>
              )}
            </div>
          ))}
        </div>
      )}
      {isReorganizing && <div className={styles.onReorganize} />}
    </div>
  );
}

Question.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  isMandatory: PropTypes.bool.isRequired,
  dropdowns: PropTypes.array,
  index: PropTypes.number.isRequired,
  updateField: PropTypes.func,
  deleteField: PropTypes.func,
  disabled: PropTypes.bool,
  isReorganizing: PropTypes.bool,
};
