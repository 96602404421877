import React from "react";
import PropTypes from "prop-types";
import RSelect, { components } from "react-select";
import { MdKeyboardArrowDown } from "react-icons/md";

const DropdownIndicator = (props) => {
  const { color, fontSize, ...rest } = props;

  return (
    <components.DropdownIndicator {...rest}>
      <MdKeyboardArrowDown size={fontSize + 10} color={color} />
    </components.DropdownIndicator>
  );
};

function Select(props) {
  const { color, alignItems, fontSize, bold, flex, backgroundColor, border, components, ...rest } = props;

  return (
    <RSelect
      {...rest}
      value={rest.options.find((option) => option.value === rest.value)}
      isSearchable={false}
      blurInputOnSelect={true}
      styles={{
        container: (provided) => ({
          ...provided,
          display: "flex",
          flexDirection: "column",
          alignItems,
          flex,
        }),
        control: (provided) => ({
          ...provided,
          backgroundColor,
          border,
          padding: "0 10px",
          width: "100%",
          cursor: "pointer",
        }),
        valueContainer: (provided) => ({ ...provided, padding: 0 }),
        singleValue: (provided) => ({
          ...provided,
          fontStyle: "normal",
          fontWeight: bold ? "bold" : "normal",
          fontSize: `${fontSize}px`,
          color,
        }),
        indicatorSeparator: (provided) => ({ ...provided, width: 0 }),
        dropdownIndicator: (provided) => ({
          ...provided,
          padding: 0,
          paddingLeft: 4,
        }),
        menu: (provided) => ({
          ...provided,
          minWidth: "200px",
        }),
        option: (provided) => ({
          ...provided,
          cursor: "pointer",
          fontSize: `${fontSize}px`,
        }),
        placeholder: (provided) => ({
          ...provided,
          fontSize: `${fontSize}px`,
          fontStyle: "normal",
          fontWeight: "normal",
          color: "white",
        }),
      }}
      components={{
        DropdownIndicator: (p) => <DropdownIndicator {...p} color={color} fontSize={fontSize} />,
        ...components,
      }}
    />
  );
}

DropdownIndicator.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.number,
};

DropdownIndicator.defaultProps = {
  color: "black",
  fontSize: 14,
};

Select.propTypes = {
  color: PropTypes.string,
  alignItems: PropTypes.oneOf(["center", "flex-start", "flex-end"]),
  fontSize: PropTypes.number,
  bold: PropTypes.bool,
  flex: PropTypes.number,
  backgroundColor: PropTypes.string,
  border: PropTypes.string,
  components: PropTypes.object,
};

Select.defaultProps = {
  color: "black",
  alignItems: "center",
  fontSize: 14,
  bold: false,
  flex: null,
  backgroundColor: "transparent",
  border: null,
  components: {},
};

export default React.memo(Select);
