import React, { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import classNames from "classnames";
import { FiberManualRecord, ChevronLeft, ChevronRight } from "@material-ui/icons";
import styles from "./calendar.module.css";
import Container from "../../components/container";
import Information from "../../components/information";
import { StateContext } from "../../contexts/state";
import { StatusContext } from "../../contexts/status";
import API from "../../api";

const monthFormat = "Y-MM";
const dayFormat = "Y-MM-DD";

export default function Calendar(props) {
  const { channel } = useContext(StateContext);
  const { updateErrorMessage } = useContext(StatusContext);
  const [selected, setSelected] = useState(moment().format(dayFormat));
  const [month, setMonth] = useState(moment().format(monthFormat));
  const [weeks, setWeeks] = useState([]);
  const [items, setItems] = useState([]);
  const [allItems, setAllItems] = useState({});

  const fetchData = useCallback(async () => {
    try {
      const data = {
        channel: channel._id,
        isArchived: false,
        date: {
          $gte: moment(selected).startOf("year").toISOString(),
          $lt: moment(selected).endOf("year").toISOString()
        }
      };
      const informations = await API.information().calendar(data);
      setAllItems(informations.data);
      if (informations.data[selected]) {
        setItems(informations.data[selected]);
      } else {
        setItems([]);
      }
    } catch (error) {
      updateErrorMessage(API.getErrorMessage(error));
    }
  }, [channel._id, selected, updateErrorMessage]);

  useEffect(() => {
    if (channel._id) fetchData();
  }, [channel, fetchData]);

  const onChangeDate = useCallback(
    date => {
      setSelected(date);
      if (allItems[date]) {
        setItems(allItems[date]);
      } else {
        setItems([]);
      }
    },
    [allItems]
  );

  useEffect(() => {
    let initialDate = moment(month, monthFormat).startOf("month").startOf("week");
    let finalDate = moment(month, monthFormat).endOf("month").endOf("week");
    const _weeks = new Array(Math.ceil(finalDate.diff(initialDate, "days") / 7))
      .fill(0)
      .map((_, i) => new Array(7).fill(0).map((_, j) => initialDate.clone().add(i * 7 + j, "days")));
    setWeeks(_weeks);
  }, [month]);

  const onLastMonth = () => {
    const _month = moment(month, monthFormat).subtract(1, "month").format(monthFormat);
    setMonth(_month);
  };

  const onNextMonth = () => {
    const _month = moment(month, monthFormat).add(1, "month").format(monthFormat);
    setMonth(_month);
  };

  const getDots = date => {
    if (allItems[date] && selected !== date) {
      let dots = [];
      dots = allItems[date].map(data => ({ key: data.channel, color: `#${data.channel.slice(-6)}` }));
      dots = dots.filter((thing, index, self) => index === self.findIndex(t => t.key === thing.key));
      return (
        <div className={styles.dayDots}>
          {dots.map(dot => (
            <div key={dot.key} style={{ color: dot.color }}>
              <FiberManualRecord fontSize="inherit" color="inherit" />
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <Container {...props}>
      <div className={styles.calendar}>
        <div className={styles.header}>
          <button onClick={onLastMonth}>
            <ChevronLeft fontSize="large" />
          </button>
          <h3 className={styles.title}>{moment(month, monthFormat).format("MMMM Y")}</h3>
          <button onClick={onNextMonth}>
            <ChevronRight fontSize="large" />
          </button>
        </div>
        <div className={styles.month}>
          <div className={styles.week}>
            {weeks[0]?.map(day => (
              <div key={day.valueOf()} className={styles.dayName}>
                <span>{day.format("ddd")}</span>
              </div>
            ))}
          </div>
          {weeks.map((week, index) => (
            <div key={String(index)} className={styles.week}>
              {week.map(day => (
                <div key={day.valueOf()} className={styles.day}>
                  <button
                    className={classNames(styles.dayButton, {
                      [styles.dayButtonActive]: selected === day.format(dayFormat),
                      [styles.dayButtonNotMonth]: moment(month, monthFormat).month() !== day.month()
                    })}
                    onClick={() => {
                      if (moment(month, monthFormat).month() !== day.month()) {
                        setMonth(day.format(monthFormat));
                      }
                      onChangeDate(day.format(dayFormat));
                    }}
                  >
                    <span>{day.format("DD")}</span>
                    {getDots(day.format(dayFormat))}
                  </button>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div>
        {items.map(information => (
          <Information key={information._id} information={information} />
        ))}
      </div>
    </Container>
  );
}

Calendar.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired
  }),
  match: PropTypes.shape({
    params: PropTypes.object.isRequired
  })
};
