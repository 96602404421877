import React, { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styles from "./members.module.css";
import Container from "../../components/container";
import routes from "../../routes";
import Member from "../../components/member";
import { StateContext } from "../../contexts/state";
import { StatusContext } from "../../contexts/status";
import API from "../../api";
import translate from "../../translate";

export default function Members(props) {
  const { channel, refreshChannel } = useContext(StateContext);
  const { updateErrorMessage, updateSuccessMessage } = useContext(StatusContext);
  const [invitations, setInvitations] = useState([]);

  const getInvitations = useCallback(async () => {
    try {
      const _params = API.getParams({
        channel: channel._id,
        isArchived: false,
      });
      const response = await API.invitation().list(_params);
      setInvitations(response.data);
    } catch (error) {
      API.handleError(error);
    }
  }, [channel._id]);

  useEffect(() => {
    getInvitations();
  }, [getInvitations]);

  const onDeleteUser = async (id, fullName, type) => {
    try {
      const isConfirm = window.confirm(`Delete ${type}: ${fullName}`);
      if (isConfirm) {
        if (type === "admin") {
          await API.channel(channel._id).removeAdmin(id);
        } else if (type === "receiver") {
          await API.channel(channel._id).removeReceiver(id);
        } else {
          await API.channel(channel._id).removeSender(id);
        }
      }
      await refreshChannel();
      updateSuccessMessage("Member deleted");
    } catch (error) {
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const onResendInvitation = async (id, email) => {
    try {
      const isConfirm = window.confirm(`Resend invitation to ${email}`);
      if (isConfirm) {
        await API.invitation().resend(id);
        updateSuccessMessage("Invitation resend");
      }
    } catch (error) {
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  const onRemoveInvitation = async (id, email) => {
    try {
      const isConfirm = window.confirm(`Delete invitation: ${email}`);
      if (isConfirm) {
        await API.invitation().delete(id);
        await getInvitations();
        updateSuccessMessage("Invitation deleted");
      }
    } catch (error) {
      updateErrorMessage(API.getErrorMessage(error));
    }
  };

  return (
    <Container {...props}>
      <div className={styles.sendInvitationContainer}>
        <Link to={routes.addMember} className={styles.sendInvitationButton}>
          {translate.members.invite}
        </Link>
      </div>
      <h3 className={styles.subtitle}>{translate.members.owner}</h3>
      <Member member={channel.createdBy} isOwner />
      <h3 className={styles.subtitle}>{translate.members.admins}</h3>
      {channel.admins.length > 0 ? (
        channel.admins.map((admin) => (
          <Member
            key={admin._id}
            member={admin}
            onDeleteUser={(id, fullName) => onDeleteUser(id, fullName, "admin")}
            isOwner={channel.createdBy._id === admin._id}
          />
        ))
      ) : (
        <h5 className={styles.noMembers}>{translate.members.noUsers}</h5>
      )}
      <h3 className={styles.subtitle}>{translate.members.receivers}</h3>
      {channel.receivers.length > 0 ? (
        channel.receivers.map((receiver) => (
          <Member key={receiver._id} member={receiver} onDeleteUser={(id, fullName) => onDeleteUser(id, fullName, "receiver")} />
        ))
      ) : (
        <h5 className={styles.noMembers}>{translate.members.noUsers}</h5>
      )}
      <h3 className={styles.subtitle}>{translate.members.senders}</h3>
      {channel.senders.length > 0 ? (
        channel.senders.map((sender) => (
          <Member key={sender._id} member={sender} onDeleteUser={(id, fullName) => onDeleteUser(id, fullName, "sender")} />
        ))
      ) : (
        <h5 className={styles.noMembers}>{translate.members.noUsers}</h5>
      )}
      <h3 className={styles.subtitle}>{translate.members.pendingInvitations}</h3>
      {invitations.length > 0 ? (
        invitations.map((invitation) => (
          <Member
            key={invitation._id}
            member={invitation}
            isInvitation={true}
            onResendInvitation={onResendInvitation}
            onRemoveInvitation={onRemoveInvitation}
          />
        ))
      ) : (
        <h5 className={styles.noMembers}>{translate.members.noInvitations}</h5>
      )}
    </Container>
  );
}

Members.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }),
};
