import LocalizedStrings from "react-localization";
import moment from "moment";
import "moment/locale/es";
import "moment/locale/pt";
import en from "./translations/en";
import es from "./translations/es";
import pt from "./translations/pt";

const translate = new LocalizedStrings({ en, es, pt });

const defaultLocale =
  localStorage.getItem("locale") ||
  (Array.isArray(navigator.languages) ? navigator.languages[0] : navigator.language)?.split("-")[0] ||
  "en";

const locale = translate.getAvailableLanguages().includes(defaultLocale) ? defaultLocale : "en";

translate.setLanguage(locale);
moment.locale(locale);

const changeLanguage = _locale => {
  if (!translate.getAvailableLanguages().includes(_locale)) {
    return;
  }
  if (translate.getLanguage() !== _locale) {
    localStorage.setItem("locale", _locale);
    window.location.reload();
  }
};

export default translate;

export { changeLanguage, locale };
