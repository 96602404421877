import { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useToasts } from "react-toast-notifications";
import { StatusContext } from "../contexts/status";

export default function Toast(props) {
  const { errorMessage, successMessage, infoMessage, warningMessage } = props;
  const { addToast } = useToasts();
  const { updateErrorMessage, updateSuccessMessage, updateInfoMessage, updateWarningMessage } = useContext(StatusContext);

  useEffect(() => {
    if (errorMessage) {
      addToast(errorMessage, {
        appearance: "error"
      });
      updateErrorMessage("");
    }
  }, [addToast, errorMessage, updateErrorMessage]);

  useEffect(() => {
    if (successMessage) {
      addToast(successMessage, {
        appearance: "success",
        autoDismiss: !successMessage.includes("To finish your registration")
      });
      updateSuccessMessage("");
    }
  }, [addToast, successMessage, updateSuccessMessage]);

  useEffect(() => {
    if (infoMessage) {
      addToast(infoMessage, {
        appearance: "info"
      });
      updateInfoMessage("");
    }
  }, [addToast, infoMessage, updateInfoMessage]);

  useEffect(() => {
    if (warningMessage) {
      addToast(warningMessage, {
        appearance: "warning"
      });
      updateWarningMessage("");
    }
  }, [addToast, warningMessage, updateWarningMessage]);

  return null;
}

Toast.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
  infoMessage: PropTypes.string.isRequired,
  warningMessage: PropTypes.string.isRequired
};
