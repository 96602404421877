const postColors = [
  {
    text: "Default",
    value: "transparent",
  },
  {
    text: "Yellow",
    value: "#FFA710",
  },
  {
    text: "Red",
    value: "#FF0000",
  },
  {
    text: "Gree",
    value: "#00772C",
  },
  {
    text: "Blue",
    value: "#0080FF",
  },
  {
    text: "Light yellow",
    value: "#EAF28F",
  },
  // {
  //   text: "Pink",
  //   value: "#FFC5AB"
  // },
  {
    text: "Light green",
    value: "#B1DD8B",
  },
  {
    text: "Light blue",
    value: "#74A7FF",
  },
];

const menuData = [
  {
    title: "Resolved",
    color: "#126d0f",
    status: 0,
  },
  {
    title: "In progress (more than 50% ready )",
    color: "#4800ff",
    status: 1,
  },
  {
    title: "In progress (less than 50% ready) ",
    color: "#e67e22",
    status: 2,
  },
  {
    title: "Over due or not resolved",
    color: "#ff0000",
    status: 3,
  },
];

const constants = {
  state: {
    WORKSPACES: "workspaces",
    CHANNELS: "channels",
    WORKSPACE: "workspace",
    CHANNEL: "channel",
    USER: "user",
    TOKEN: "token",
    ROLE: "role",
    RULES: "rules",
    IS_LOGGED_IN: "is_logged_in",
    STEP: "step",
  },
  status: {
    IS_LOADING: "is_loading",
    ERROR_MESSAGE: "error_message",
    SUCCESS_MESSAGE: "success_message",
    INFO_MESSAGE: "info_message",
    WARNING_MESSAGE: "warning_message",
  },
  postColors,
  menuData,
};

module.exports = constants;
